import React, { useState, useEffect } from "react"
import closecircle from "../img/closecircle.svg"

import "./AssessmentConfirmingModal.css"


// Confirming modal is displayed when the user needs to confirm an action as in `Review Answers` screen
// Note: Styling of the text will vary if there is a title or not in the modal (per agreement with designer)
export const AssessmentConfirmingModal = ({ handleClose, handleContinue, title, text, text2 }) => {
  const [cooldownActive, setCooldownActive] = useState(true)

  // Adding cooldown effect to the buttons when accessing the screen
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCooldownActive(false)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div className="assessment-confirming-modal"> 

        <div className="close-button-container">
          <button 
            className="close-button"
            onClick={handleClose} 
            disabled={cooldownActive}
            aria-disabled={cooldownActive}
          >
            <img src={closecircle} alt="" />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        { title && (<h2>{title}</h2>)}

        <p className={`${title ? "warning-modal-text" : "confirming-modal-text"}`}>
          {text}
        </p>
        { text2 && (
          <p className={`${title ? "warning-modal-text" : "confirming-modal-text"}`}>{text2}</p>
        )}

        <div className="confirm-button-container">
          <button
            className="confirm-button"
            disabled={cooldownActive}
            aria-disabled={cooldownActive}
            onClick={handleContinue}
          >
            I Confirm
          </button>
        </div>
    </div>
  )
}
