import Button from "./Button"

import './YesNoButtons.css'

function YesNoButtons({ id, question = "", onEvent, onSubmit }) {
  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const handleClick = (e) => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: e.target.value })
    onSubmit({ [id]: e.target.value })
  }
  return (
    <div className="yes-no">
      <div className="flex flex-grow items-center">{question}</div>
      <div className="yes-no-group">
        {options.map(({ value, label }) => (
          <Button value={value} key={label} onClick={handleClick} className="primary outline rect">
            {label}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default YesNoButtons