import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { toast } from 'react-toastify'
import { Assessment } from "../components/Assessment"
import SummaryPage from "./Summary"
import OutcomePage from "./outcome/Outcome"
import { decodeAssessmentState } from "../spec/utils"
import { createComponent } from "../components/AssessmentComponents"
import LayoutScreen from "../components/Layout"
import AuditContext from "../audit"
import AssessmentReferenceNumber from "../components/AssessmentReferenceNumber"
import { client } from "../client"
import { usePrompt } from "../prompt"
import { useAssessment } from "../AssessmentContext"
import { ClinicianCompletionToast } from '../components/ClinicianToast'

import "./Assessment.css"

export default function AssessmentPage() {
	const { assessmentId } = useParams()

	const { 
		spec, setSpec, 
		state, setState, 
		assessmentNumber, setAssessmentNumber,
		onEvent,
	} = useAssessment()

	usePrompt("one", state && !state.isKickout, onEvent, "assessment")

	let assessmentScreen = undefined
	if (state.isTaking || state.isEditing) {
		assessmentScreen = spec.children[state.index]
	}

	let dnuScreen = undefined
	if (state.isKickout) {
		dnuScreen = spec.kickoutScreens.filter((x) => x.id === state.kickoutReason)[0]
	}

	let currentScreen = assessmentScreen || dnuScreen
	const canGoBack = state.isTaking || state.isEditing
	const hidingBackButton = currentScreen && !!currentScreen.hideBackButton

	const showBackButton = !hidingBackButton && canGoBack
	const className = state.isTaking || state.isEditing ? "assessment-main" : "assessment-completed"

	useEffect(() => {
		// need to sanitize assessmentId to ensure there is no "&assessmentState=outcome"
		const assessmentIdSanitized = assessmentId.split('&')[0]

		client
			.getState({ assessmentid: assessmentIdSanitized })
			.then((response) => {
				const remoteSpec = JSON.parse(response.spec)
				const remoteState = decodeAssessmentState(response.state)
				setSpec(remoteSpec)
				setState(remoteState)
				setAssessmentNumber(response.assessmentnumber)
			})
			.catch((e) => console.error(e))
	}, [assessmentId])

	// show content only when spec and state are loaded
	const [contentReady, setContentReady] = useState(false)
	useEffect(() => {
		// can be null or undefined
		if (!spec.loading && !state.loading) {
			setContentReady(true)
		}
	}, [spec, state])

	// Check the assessment state, the answers for kickout screens and if the user is a clinician to display the toast
	const role = sessionStorage.getItem("role")
	const isClinicianUser = role === "clinicians" || role === "clinicianLeads"
	const isCompleted = state.isOutcome && state.isCompleted 
	const isKickoutScreen = Boolean(state.answers?.dnu) || Boolean(state.answers?.abdu) || Boolean(state.answers?.adbu1) || Boolean(state.answers?.adopbu)
	const isDNUScreen = state.kickoutReason ===	"DNUScreen"

	// Specific use case to display the toast before the user has completed the assessment
	const isUserBuyingForSelf = state.answers?.buyingForSelf === "no"

	const displayToast = isClinicianUser && (
		(isCompleted && isKickoutScreen) || (isUserBuyingForSelf || isDNUScreen)
	)

	useEffect(() => {
	  if (displayToast) {
		toast(<ClinicianCompletionToast completedAssessment={isCompleted}/>, {autoClose: false, closeOnClick: false, closeButton:false, draggable: false})
	  }
	}, [displayToast, isCompleted, isDNUScreen, isUserBuyingForSelf])

	return (
		<AuditContext.Provider value={null}>
			<div className={`${className} h-screen`}>
				{ state.outcome === "" 
					? <LayoutScreen 
							context="assessment"
							client={client} 
							state={state} 
							setState={setState} 
							assessmentId={assessmentId} 
							currentScreen={currentScreen} 
							showBackButton={showBackButton}
						>
							{ contentReady && <div className="assessment-container">
								{ (state.isTaking || state.isEditing) && <Assessment /> }
								{ state.isReviewing && <SummaryPage
									client={client} 
									spec={spec} 
									answers={state.answers} 
									setState={setState}
									assessmentId={assessmentId} 
								/> }

								{ state.isKickout && createComponent(spec.kickoutScreens.filter((x) => x.id === state.kickoutReason)[0], { onEvent }) }
								{ (state.isKickout || state.outcome !== "") && <AssessmentReferenceNumber state={state} assessmentNumber={assessmentNumber}  /> }
							</div> }
						</LayoutScreen>
					: <OutcomePage
						client={client}
						spec={spec}
						state={state}
						setState={setState}
						assessmentId={assessmentId}
						assessmentNumber={assessmentNumber} />
				}
			</div>
		</AuditContext.Provider>
	)
}
