import React from "react"
import { Link } from "react-router-dom"
import LayoutScreen from "../../components/Layout"
import errorCodeImg from "../../img/403.svg"
import "./ErrorPages.css"

function ForbiddenPage() {
  return (
    <LayoutScreen>
      <div className="error-page-content forbidden">
        <img src={errorCodeImg} alt="forbidden page illustration" />

        <h1 role="alert">
          You do not have permission to access this resource.
        </h1>

        <Link to="/" className="btn secondary text-center" replace={true}>
          Back to Homepage
        </Link>
      </div>
    </LayoutScreen>
  )
}

export default ForbiddenPage
