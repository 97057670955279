import React, { useContext, useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"

import apple from "../icn/apple.png"
import google from "../icn/g-logo.png"
import icons from "../icn/index"
import ImageButton from "./ImageButton"
import { ReactComponent as ProfileIcon } from "../icn/profile.svg"
import { AssessmentConfirmingModal } from "./AssessmentConfirmingModal"
import { subscribe } from "../subscribers"
import { authClient } from "../client"
import store from "../store"
import { skipPrompt } from "../prompt"
import { useAssessment } from "../AssessmentContext"
import ModalContext from "../modal"

import "./AuthWrapper.css"

export function popup(target, name) {
  const q = function(e, t) {
    var i = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width,
      o = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height
    return {
      left: i / 2 - e / 2 + window.screenLeft,
      top: o / 2 - t / 2 + window.screenTop,
    }
  }
  const top = q(700, 700).top
  const left = q(700, 700).left
  const features = `width=700,height=700,top=${top},left=${left},resizable=no,location=no,menubar=no`
  window.open(target, name, features)
}

export function googleAuth(mode, transfer = false) {
  store.dispatch({ type: "GOOGLE_AUTH_START", mode, transfer })
  popup(buildGoogleRedirect(), "GoogleAuth")
  return subscribe("google")
}

export function appleAuth(mode, transfer = false) {
  store.dispatch({ type: "APPLE_AUTH_START", mode, transfer })
  window.AppleID.auth.signIn()
  return subscribe("apple")
}

function buildGoogleRedirect() {
  const params = {
    scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    access_type: "offline",
    redirect_uri: `${window.location.origin}/oauth/google`,
    response_type: "code",
    client_id: "934195914927-gs5ceipt1i17l9tfhc3ab2nlbv5m6hf5.apps.googleusercontent.com",
    prompt: "consent",
  }
  const base = "https://accounts.google.com/o/oauth2/v2/auth"

  const qs = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join("&")
  return encodeURI(`${base}?${qs}`)
}

export function Auth({ onSelect, onContinue, showGuestLogin = true, assessmentState }) {
  const setModal = useContext(ModalContext)
  const [cooldownActive, setCooldownActive] = useState(true)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false) // Initialize with the button enabled.
  const buttonCooldown = 1000 // 1 second cooldown
  const navigate = useNavigate() // Call useNavigate here
	const { assessmentId } = useParams()
  const { state } = useAssessment()


  // Adding cooldown effect to the buttons when accessing the screen
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCooldownActive(false)
    }, buttonCooldown)

    return () => clearTimeout(timeoutId)
  }, [])

  const handleButtonClick = async (authType, authFunction) => {
    if (isButtonDisabled) return
    
    onSelect(authType, async (mode) => {
      const ret = await authFunction(mode)
      onContinue(ret)
    })
  }

  const handleContinueClick = () => {
    setModal()
    handleButtonClick("guest", authClient.guestLogin)
  }

  const showGuestConfirmationModal = () => {
    setModal(() => (
      <AssessmentConfirmingModal
        handleClose={() => setModal()}
        handleContinue={handleContinueClick}
        title="Continue as a Guest"
        text="You will have to re-enter your information each time you want to purchase Avanafil OTC if you continue as a guest."
        text2="You will also have a chance to create an account later."
      />
    ))
  }
  
  // Display email option in all auth screens except in the sign in screen
  const checkLocation = (page) => {
    const location = window.location.href
    return location.includes(page)
  }
  const isSignInScreen = checkLocation("/sign-in")
  const isCreateAccountScreen = checkLocation("/create-account")

  
  const handleEmailButtonClick = (e, navigate) => { // Accept navigate as a parameter
    sessionStorage.setItem("continuewithEmail", true)
    skipPrompt()
    e.preventDefault()
    let url = `/create-account/${assessmentId}`
    if (assessmentState) {
      url += `?assessmentState=${assessmentState || state.state }`
    }
    navigate(url)
  }  

  const disabledButton = isButtonDisabled || cooldownActive
  const displayEmailButton = !isSignInScreen && !isCreateAccountScreen

  return (
    <div className="auth-image-button-container">
      <ImageButton src={google} onClick={() => handleButtonClick("google", googleAuth)} disabled={disabledButton}>
        Continue with Google
      </ImageButton>

      <ImageButton src={apple} onClick={() => handleButtonClick("apple", appleAuth)} disabled={disabledButton}>
        Continue with Apple
      </ImageButton>

      { displayEmailButton && (
        <ImageButton src={icons["email"]} onClick={(e) => handleEmailButtonClick(e, navigate)} disabled={disabledButton}>
          Continue with Email
        </ImageButton>
      )}

      {showGuestLogin && (
        <>
          <div className="auth-or-text">
            Or
          </div>
          <ImageButton
            image={<ProfileIcon width={24} fill="black" />}
            onClick={showGuestConfirmationModal}
            disabled={disabledButton}
          >
            Continue as a Guest
          </ImageButton>
        </>
      )}
    </div>
  )
}
