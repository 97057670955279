import { useState } from 'react'
import { options } from './states'

import "./BillingInformation.css"

export function BillingAddress({ isUserAuthenticated, state, setState, errors, setErrors }) {
  const [touched, setTouched] = useState({
    firstname: false,
    lastname: false,
    line1: false,
    city: false,
    state: false,
    zip: false,
  })

  console.log(state)

  const [saveAddress, setSavedAddress] = useState(false)

  const handleSavingAddress = () => {
    setSavedAddress(!saveAddress)
  }

  const handleErrors = ({ name, value = '' }) => {
    const newErrors = { ...errors };
  
    // Handle first name errors
    if (name === 'firstname') {
      if (!value.trim()) {
        newErrors.firstname = 'First name is required';
      } else if (value.length > 150) {
        newErrors.firstname = 'First name cannot be greater than 150 characters';
      } else {
        delete newErrors.firstname;
      }
    }
  
    // Handle last name errors
    if (name === 'lastname') {
      if (!value.trim()) {
        newErrors.lastname = 'Last name is required';
      } else if (value.length > 150) {
        newErrors.lastname = 'Last name cannot be greater than 150 characters';
      } else {
        delete newErrors.lastname;
      }
    }
  
    // Handle address line 1 errors
    if (name === 'line1') {
      if (!value.trim()) {
        newErrors.line1 = 'Address Line 1 is required';
      } else if (value.length > 150) {
        newErrors.line1 = 'Address Line 1 cannot be greater than 150 characters';
      } else {
        delete newErrors.line1;
      }
    }
  
    // Handle city errors
    if (name === 'city') {
      if (!value.trim()) {
        newErrors.city = 'City is required';
      } else if (value.length > 150) {
        newErrors.city = 'City cannot be greater than 150 characters';
      } else {
        delete newErrors.city;
      }
    }
  
    // Handle state errors
    if (name === 'state') {
      if (!value) {
        newErrors.state = 'State is required';
      } else {
        delete newErrors.state;
      }
    }
  
    // Handle zip errors
    if (name === 'zip') {
      if (!value.trim()) {
        newErrors.zip = 'ZIP code is required';
      } else if (!/^\d{5}(?:-\d{4})?$/.test(value)) {
        newErrors.zip = 'ZIP code should be 5 or 9 digits';
      } else {
        delete newErrors.zip;
      }
    }
  
    setErrors(newErrors);
  };
  

  const update = (e) => {
    const { name, value } = e.target

    const updatedState = {
      ...state,
      [name]: value,
    };

    handleErrors({ name, value })

    setState({
      ...updatedState,
    });

    sessionStorage.setItem('billingAddress', JSON.stringify({
      ...updatedState,
    }));
  }

  const handleFocus = (e) => {
    const { name } = e.target
    setTouched({ ...touched, [name]: true })
  }

  const handleBlur = (e) => {
    const { name, value } = e.target
    handleErrors({ name, value })
  }

  return (
    <div className="billing-address-container">
      <h2 className="billing-address-title">Enter Billing address</h2>

      { isUserAuthenticated 
        ? (
        <div className="info-checkbox-container">
          <div className="save-address-checkbox md:hidden">
            <input type="checkbox" id="saveAddress" name="save-address" value={saveAddress} onChange={handleSavingAddress}/>
            <label htmlFor="saveAddress">Save this address to my account</label>
          </div>
        </div>
        )
        : null
      }

      <div className="address-form">
        <label className={`form-group ${ touched.firstname && !!errors.firstname ? 'error' : '' } md:col-span-3`} htmlFor="firstname">
          <span className="required">First Name</span>

          <div>
            <input
              type="text"
              id="firstname"
              name="firstname"
              value={state.firstname}
              onChange={update}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!errors.firstname}
              autoComplete='given-name'
            />
            { touched.firstname && (
              <>
                { errors.firstname && (
                  <span className="error-text">{ errors.firstname }</span>
                )}
              </>
            )}
          </div>
        </label>

        <label className={`form-group ${ touched.lastname && !!errors.lastname ? 'error' : '' } md:col-span-3`} htmlFor="lastname">
          <span className="required">Last Name</span>

          <input
            type="text"
            id="lastname"
            name="lastname"
            value={state.lastname}
            onChange={update}
            onFocus={handleFocus}
            onBlur={handleBlur}
            aria-invalid={!!errors.lastname}
            autoComplete='family-name'
          />

          { touched.lastname && (
              <>
              { errors.lastname && (
                <span className="error-text">{ errors.lastname }</span>
              )}
            </>
          )}
        </label>

        <label className={`form-group ${ touched.line1 && !!errors.line1 ? 'error' : '' } md:col-span-3`} htmlFor="line1">
          <span className="required">Address Line 1</span>

          <div>
            <input
              type="text"
              id="line1"
              name="line1"
              value={state.line1}
              onChange={update}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!errors.line1}
              autoComplete='address-line1'
            />

            { touched.line1 && (
                <>
                { errors.line1 && (
                  <span className="error-text">{ errors.line1 }</span>
                )}
              </>
            )}
          </div>
        </label>

        <label className="form-group md:col-span-3" htmlFor="line2">
          <span>Address Line 2</span>

          <input
            type="text"
            id="line2"
            name="line2"
            value={state.line2}
            onChange={update}
            onFocus={handleFocus}
            autoComplete='address-line2'
          />
        </label>

        <label className={`form-group ${ touched.city && !!errors.city ? 'error' : '' } md:col-span-2`} htmlFor="city">
          <span className="required">City</span>

          <div>
            <input
              type="text"
              id="city"
              name="city"
              value={state.city}
              onChange={update}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!errors.city}
              autoComplete='address-level2'
            />

            { touched.city && (
                <>
                { errors.city && (
                  <span className="error-text">{ errors.city }</span>
                )}
              </>
            )}
          </div>
        </label>

        <label className={`form-group ${ touched.state && !!errors.state ? 'error' : '' } md:col-span-2`} htmlFor="state">
          <span className="required">State</span>

          <div>
            <select id="state" name="state" value={state.state} onChange={update} onFocus={handleFocus} onBlur={handleBlur}>
              <option value=""></option>
              {options.map((option, index) => (
                <option key={index} value={option.value}>{option.text}</option>
              ))}
            </select>

            { touched.state && (
              <>
                {errors.state && (
                  <span className="error-text">State is required</span>
                )}
              </>
            )}
          </div>
        </label>
          
        <label className={`form-group ${ touched.zip && !!errors.zip ? 'error' : '' } md:col-span-2`} htmlFor="zip">
          <span className="required">Zip Code</span>

          <div>
            <input
              type="text"
              id="zip"
              name="zip"
              value={state.zip}
              onChange={update}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!errors.zip}
            />

            { touched.zip && (
                <>
                { errors.zip && (
                  <span className="error-text">{ errors.zip }</span>
                )}
              </>
            )}
          </div>
        </label>

        { isUserAuthenticated 
          ? (
          <div className="info-checkbox-container">
            <div className="save-address-checkbox hidden md:block">
              <input type="checkbox" id="saveAddress" name="save-address" value={saveAddress} onChange={handleSavingAddress}/>
              <label htmlFor="saveAddress">Save this address to my account</label>
            </div>
          </div>
          )
          : null
        }
      </div>
    </div>
  )
}
