import Button from "../AssessmentComponents/Button"

function ModalBaseScreen({ children, onSubmit }) {
  return (
    <div className="outer-modal-container">
      <div className="inner">
        {children}
      </div>

      <div className="button-container">
        <Button onClick={onSubmit} className="secondary">
          Back
        </Button>
      </div>
    </div>
  )
}

export default ModalBaseScreen;