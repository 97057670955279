import React, { useEffect } from "react"
import { ToastContainer } from "react-toastify"
import Modal from "react-modal"
import { Routes, Route, Outlet } from "react-router-dom"
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { CheckoutProvider } from "./CheckoutContext"
import { AssessmentProvider } from "./AssessmentContext"
import { useNetworkStatus } from "./hooks"
import LandingPage from "./pages/LandingPage"
import AssessmentPage from "./pages/Assessment"
import SignIn from "./pages/SignIn"
import CreateAccount from "./pages/CreateAccount"
import ReturnPage from "./pages/Return"
import ModalContext from "./modal"
import Checkout from "./pages/CheckoutTmp"
import QRCodePage from "./pages/QRCode"
import Unauthorized from "./pages/errorPages/401Page"
import ForbiddenPage from "./pages/errorPages/403Page"
import PageNotFound from "./pages/errorPages/404Page"
import MethodNotAllowed from "./pages/errorPages/405Page"
import RequestTimeout from "./pages/errorPages/408Page"
import InternalServerError from "./pages/errorPages/500Page"
import BadGateway from "./pages/errorPages/502Page"

import "./App.css"
import "./components/Modal.css"
import history from "./history"
import store from "./store"
import { client, authClient } from "./client"
import RouteChangeHandler from "./components/RouteChangeHandler"
import OfflineNotification from "./components/OfflineNotification/OfflineNotification"

fetch("/config/config.json")
  .then((res) => res.json())
  .then((config) => store.dispatch({ type: "FETCH_CONFIG_SUCCESS", config }))

Modal.setAppElement(document.getElementById("root"))

export class ModalProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stack: [],
      modalProps: {},
    }
  }

  setModal = (modal, modalProps = {}) => {
    this.setState((state) => {
      const { stack } = state
      if (!modal) {
        const [_current, ...rest] = stack
        return { stack: rest, modalProps: {}}
      }
      return { stack: [modal, ...stack], modalProps }
    })
  }

  render() {
    const { stack, modalProps } = this.state
    const isModalOpen = stack.length > 0
    const isCheckoutWorkflow = window.location.href.includes("checkout")

    const closeModal = () => this.setModal()

    const modalClassName = modalProps.className ? modalProps.className : "exit-modal"
    const overlayClassName = `${modalClassName}-overlay overlay`

    return (
      <>
        <ModalContext.Provider value={this.setModal}>{this.props.children}</ModalContext.Provider>

        <Modal
          className={modalClassName}
          overlayClassName={overlayClassName}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          showCloseButton={!isCheckoutWorkflow}
          {...modalProps}
        >
          {isModalOpen && stack[0]()}
        </Modal>
      </>
    )
  }
}

function Root() {
  return (
    <CheckoutProvider>
      <AssessmentProvider>
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </AssessmentProvider>
    </CheckoutProvider>
  )
}

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route
          index
          element={
            <ModalProvider key="landing">
              <LandingPage client={client} />
            </ModalProvider>
          }
        />
        <Route
          path="assessment/:assessmentId/"
          element={
            <ModalProvider key="assessment">
              <AssessmentPage />
            </ModalProvider>
          }
        />
        <Route path="return/:assessmentId" element={<ReturnPage />} />
        <Route path="create-account/:assessmentId?" element={<CreateAccount authClient={authClient} />} />
        <Route path="sign-in/:assessmentId?" element={<SignIn authClient={authClient} />} />
        <Route path="checkout/:assessmentId" element={<Checkout />} />
        <Route path="qr/:assessmentId" element={<QRCodePage />} />
        <Route path="/401" element={<Unauthorized />} />
        <Route path="/403" element={<ForbiddenPage />} />
        <Route path="/405" element={<MethodNotAllowed />} />
        <Route path="/408" element={<RequestTimeout />} />
        <Route path="/500" element={<InternalServerError />} />
        <Route path="/502" element={<BadGateway />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}

function App() {
  const { isOnline } = useNetworkStatus()

  useEffect(() => {
    const handleServerError = (event) => {
      if (event.detail.status === 500) {
        history.push('/500')
      }
    }

    document.addEventListener('serverError', handleServerError);

    return () => {
      document.removeEventListener('serverError', handleServerError);
    }
  }, [])

  return (
    <>
    <div style={{position: "absolute", top: 0}} id="topper"></div>
    <Provider store={store}>
      <div className="App">
        <HistoryRouter history={history}>
          <RouteChangeHandler />
          <AppRoutes />
        </HistoryRouter>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        closeButton={false}
        draggable={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <ModalProvider showCloseButton={false} canClose={false}>
        <OfflineNotification isOnline={isOnline} />
      </ModalProvider>
    </Provider>
    </>
  )
}

export default App
