import "./ProgressBar.css";
import "./CheckoutProgress.css";

export default function CheckoutProgress({ cur }) {
  const steps = [
    "Select Product",
    "Shipping Address",
    "Shipping Method",
    "Payment Info",
    "Preview Order",
    "Order Confirmation"
  ]

  const lastStep = steps.length - 1; // Index of the last step
  const children = steps.map((s, i) => {
    let state = "";
    if (i < cur || (cur === lastStep && i === cur)) {
      state = "completed";
    } else if (i === cur) {
      state = "current";
    } else {
      state = "incomplete";
    }
    return <Step key={s} title={s} state={state} />;
  });

  const numSpaces = steps.length - 1;
  const progress = Math.min(((cur / numSpaces) * 100).toFixed(2), 100);

  return (
    <div 
      className="checkout-progress"
      role="progressbar"
      aria-valuenow={progress} 
      aria-valuemin="0" 
      aria-valuemax="100" 
      aria-label={`Step ${cur + 1} of ${steps.length}`}
    >
      <div className="relative flex flex-col justify-center">
        <div className="absolute w-full flex justify-between">
          {children}
        </div>
        <div className="h-[2px] bg-secondary-500">
          <div className="inner-bar" style={{ width: `${progress}%` }} data-testid="progress-bar" />
        </div>
      </div>
    </div>
  );
}

export function Step({ title, state }) {
  return (
    <div className={`progress-step ${state}`} aria-current={state === "current" ? "step" : undefined}>
      <span className="sr-only md:not-sr-only">{title}</span>
    </div>
  );
}

export function SmoothProgress({ progress }) {
  return (
    <div 
      className="progress-mobile"
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin="0"
      aria-valuemax="100"
      aria-label={`Progress: ${progress}%`}
    >
      <div className="inner-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
}

export function AdjustedProgress({ cur }) {
  // HACK: this is to map the current screen index to the corresponding progress bar step that should be shown
  // (they don't map 1 to 1)
  const m = {
    0: 0,
    1: 1,	    
    2: 2,	    
    3: 3,	    
    4: 4,	    
    5: 5,	    
    6: 6

  }
  cur = m[cur]
  return <CheckoutProgress cur={cur} /> 
}