import Button from './AssessmentComponents/Button'

export const ModalAssessmentButton = ({ children, onClick, value, className }) => {
  return (
    <Button onClick={onClick} value={value} className={className}>
      {children}
    </Button>
  )
}

export const BpModal = ({ answers: {systolic, diastolic}, onConfirm, onClose }) => (
  <>
    <h2>
      It looks like you may have mis-typed your blood pressure
    </h2>

    <div className='py-4'>
      <p>
        You said your BP was <span className="font-bold">{systolic}/{diastolic}</span>. Usually the top number is higher than the bottom number. Is what you have entered correct?
      </p>
    </div>

    <ModalAssessmentButton onClick={onConfirm}>
      This is correct
    </ModalAssessmentButton>

    <ModalAssessmentButton onClick={onClose} className="primary outline">
      Change
    </ModalAssessmentButton>
  </>
)

export const BpNumbersRecentModal = ({ onConfirm, onEvent }) => {
  const handleOnClick = (e) => {
    const { value } = e.target

    onEvent({componentId: "bpNumbersRecent", type: "UPDATED_ANSWER", newValue: value})
    onConfirm({bpNumbersRecent: value})
  }

  return (
    <>
      <h2 className='text-secondary-500'>
        Was your blood pressure measured within the last 3 months?
      </h2>

      <div className='py-4'>
        <span>
        </span>
      </div>

      <button className='btn primary' value="yes" onClick={handleOnClick}>Yes</button>
      <button className='btn primary outline' value="no" onClick={handleOnClick}>No</button>
    </>
  )
}