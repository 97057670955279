import './ClinicianToast.css'

export function ClinicianCompletionToast({ completedAssessment }) {
    return (
      <>
        { 
          completedAssessment && (
          <span className="text-black">You have successfully completed this health survey.</span>
        )}
        <button
          className="block mt-1 text-blue-800 text-left underline-offset-2 underline"
          onClick={e => {
            e.preventDefault()
            const s = window.open("", "_self")
            s.close()
        }}>
          Click here to return to the Study Administration Portal
        </button>
      </>
    )
  }
  