import { useState, useEffect } from 'react'
import './Button.css'

function Button({ onClick, className = "primary", children, ...rest }) {
  const [cooldownActive, setCooldownActive] = useState(true);

  const { disabled, ...newRest } = rest;
  if (disabled !== undefined) rest = newRest;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCooldownActive(false);
    }, 1000); // configure cooldown time here

    // This function will be called when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return (
    <button 
      className={`btn ${className}`} 
      onClick={onClick} 
      disabled={cooldownActive || disabled}
      {...newRest}
    >
      {children}
    </button>
  )
}

export default Button