import React from "react"
import "../css/DFL.css"

export function DFL() {
  return (
    <div className="dfl-body">
      <div id="title" className="dfl-section">
        <h1 className="dfl-header">Drug Facts</h1>
        <hr/>
        <div id="active-ingredients">
          <div className="dfl-sub-header">
            <span>Active ingredient (in each tablet)</span>
            <span>Purpose</span>
          </div>

          <div className="dfl-content">
            <span>Avanafil 50 mg</span>
            <span className="ellipses">
              ...........................................................................................
            </span>
            <span>Treats erectile dysfunction</span>
          </div>
        </div>
      </div>

      <div className="dfl-section">
        <h2 className="dfl-header">Use</h2>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              for men 18 years and older to help with recurring difficulties getting or keeping an erection hard enough for sex
            </li>
          </ul>
        </div>
      </div>

      <div className="dfl-section">
        <h2 className="dfl-header">Warnings</h2>
        <div className="dfl-content">
          <span className="highlight"><strong>Heart Alert</strong></span>
          <ul className="dfl-unordered-list">
            <li>
              <span className="highlight">
                <strong>erectile dysfunction may be a sign that you have a heart problem</strong>
              </span>
            </li>
            <li>
              <strong>this product is not safe for some men with heart problems</strong>
              <ul className="dfl-unordered-list">
                <li>
                  <strong>Do not use</strong> if you have ever had heart failure
                </li>
                <li>
                  <strong>Ask a doctor before use</strong> if you have ever had any of these heart issues:
                  <ul className="dfl-unordered-list flex-container">
                    <li className="item-40">heart attack or stroke</li>
                    <li>heart procedure or heart surgery</li>
                  </ul>
                  <ul className="dfl-unordered-list flex-container">
                    <li className="item-40">angina or chest pain</li>
                    <li>irregular heartbeat or heart valve issues</li>
                  </ul>
                  <ul className="dfl-unordered-list">
                    <li>low blood pressure or uncontrolled high blood pressure</li>
                    <li>breathlessness, chest discomfort or pain with physical activity</li>
                    <li>dizziness or fainting when you stand up</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <span>
                <strong>if you experience symptoms of a heart attack</strong> during or after sex, 
                <strong> seek emergency medical help right away.</strong> Tell the doctor if you have taken this product. Symptoms include:
              </span>
              <ul className="dfl-unordered-list flex-container">
                <li className="item-60">pain or pressure in the chest, jaw, neck or arms</li>
                <li>get dizzy or feel faint</li>
              </ul>
              <ul className="dfl-unordered-list flex-container">
                <li className="item-60">unexplained indigestion, nausea or vomiting</li>
                <li>shortness of breath</li>
              </ul>
            </li>
          </ul>
        </div>

        <hr />

        <span><strong>Do not use</strong> if you:</span>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              <strong>
                <span className="highlight">have ever been prescribed any nitrate medicine</span>
              </strong> for chest pain or heart failure
              <ul className="dfl-unordered-list">
                <li>
                  <strong>never take with nitrate medicines</strong> including isosorbide or nitroglycerin
                </li>
                <li>taking nitrate medicines with this product could cause a life-threatening drop in blood pressure and can lead to a stroke or heart attack</li>
                <li>nitrates may be tablets, sprays, creams, patches or injectable products</li>
                <li>ask your doctor or pharmacist if you are unsure if you are taking a nitrate</li>
              </ul>
            </li>
            <li>
              <strong>
                <span className="highlight">have ever used nitrite drugs known as “poppers”</span>
              </strong> (street drugs such as amyl or butyl nitrite)
              <ul className="dfl-unordered-list">
                <li>taking nitrite drugs with this product could cause a life-threatening drop in blood pressure and can lead to a stroke or heart attack</li>
              </ul>
            </li>
            <li>have been told by a doctor that it is not safe for you to have sex</li>

            <li>
              take any of these medicines:
              <ul className="dfl-unordered-list flex-container">
                <li className="item-60">itraconazole, ketoconazole (antifungals)</li>
                <li>HIV medicine</li>
              </ul>
              <ul className="dfl-unordered-list flex-container">
                <li className="item-60">clarithromycin, telithromycin (antibiotics)</li>
                <li>nefazodone (antidepressant)</li>
              </ul>
              <ul className="dfl-unordered-list">
                <li>riociguat or other medicines for pulmonary hypertension (high blood pressure in the lungs)</li>
              </ul>
            </li>

            <li>
              have any of these medical conditions:
              <ul className="dfl-unordered-list flex-container">
                <li className="item-40">ever had heart failure</li>
                <li>severe kidney disease</li>
              </ul>
              <ul className="dfl-unordered-list flex-container">
                <li className="item-40">retinitis pigmentosa</li>
                <li>severe liver disease</li>
              </ul>
            </li>
            <li>do not have erectile dysfunction as it will not work</li>
            <li>are under 18 years old</li>
            <li>have ever had an allergic reaction to avanafil or any other ingredients in this product</li>
          </ul>
        </div>

        <hr />

        <div>
          <strong>Ask a doctor before use if you have:</strong>
          <ul className="dfl-unordered-list">
            <li>ever had any of these heart issues as this product is not safe for some men with heart problems:
              <ul className="dfl-unordered-list flex-container">
                <li className="item-40">heart attack or stroke</li>
                <li>heart procedure or heart surgery</li>
              </ul>
              <ul className="dfl-unordered-list flex-container">
                <li className="item-40">angina or chest pain</li>
                <li>irregular heartbeat or heart valve issues</li>
              </ul>
              <ul className="dfl-unordered-list">
                <li>low blood pressure or uncontrolled high blood pressure</li>
                <li>breathlessness, chest discomfort or pain with physical activity</li>
                <li>dizziness or fainting when you stand up</li>
              </ul>
            </li>
            <li>ever had these non-heart related issues:
              <ul className="dfl-unordered-list">
                <li>a sudden loss of vision or hearing</li>
                <li>an erection that has lasted more than 4 hours</li>
                <li>a deformed penis shape</li>
                <li>sickle cell anemia, multiple myeloma, or leukemia</li>
                <li>bleeding problems or have had stomach ulcers</li>
              </ul>
            </li>
          </ul>
        </div>

        <hr />
        <div>
          <strong>Ask a doctor or pharmacist before use if you are:</strong>
          <ul className="dfl-unordered-list">
            <li>taking medicines for blood pressure control or enlarged prostate (BPH)</li>
            <li>using other treatments for erectile dysfunction</li>
            <li>unsure if you are taking nitrate medicine. These may be tablets, sprays, creams, patches or injectable products.</li>
          </ul>
        </div>

        <hr />
        <div>
          <strong>When using this product</strong>
          <ul className="dfl-unordered-list">
            <li>you may experience these common side effects: headache, facial flushing, back pain</li>
            <li>do not have more than 3 alcoholic drinks</li>
            <li>this product does not protect against sexually transmitted diseases (STDs)</li>
          </ul>
        </div>

        <hr />
        <div>
          <span>
            <strong>Seek medical help right away</strong> if you experience any of these symptoms while using this product and tell the doctor you have taken this product:
          </span>
          <ul className="dfl-unordered-list">
            <li>an erection that lasts more than 4 hours</li>
            <li>sudden vision loss in one or both eyes</li>
            <li>sudden hearing loss in one or both ears</li>
          </ul>
        </div>

        <hr />
        <div>
          <strong>Stop use and ask a doctor if</strong> you get an allergic reaction (e.g., hives, swelling of tongue or lips, rash) to this product.
        </div>
        <div>
          <strong>Keep out of reach of children.</strong> In case of overdose, get medical help or contact a Poison Control Center right away.
        </div>
      </div>

      <div id="directions" className="dfl-section">
        <h2 className="dfl-header">Directions</h2>
        <div className="dfl-content">
          <table>
            <tbody>
              <tr>
                <td colSpan="2" className="left-col">men 18 to 74 years</td>
                <td colSpan="3">
                  <ul className="dfl-unordered-list">
                    <li>take 1 tablet about 30 minutes before you plan to have sex</li>
                    <li><strong>do not take</strong> more than 1 tablet a day</li>
                    <li><strong>do not take</strong> this product within 24 hours of taking any other treatment for erectile dysfunction</li>
                    <li>sexual stimulation is needed to get an erection</li>
                    <li>stop use and ask a doctor if this product does not work for you after trying one tablet per day on 3 different days</li>
                    <li>avoid splitting tablets</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="left-col">men 75 years or older</td>
                <td colSpan="3">
                  <strong>ask a doctor before use</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="left-col">you do not have erectile dysfunction</td>
                <td colSpan="3">
                  <strong>do not use</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="left-col">under 18 years of age</td>
                <td colSpan="3">
                  <strong>do not use</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="left-col">women</td>
                <td colSpan="3">
                  <strong>do not use</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="other-information" className="dfl-section">
        <h2 className="dfl-header">Other Information</h2>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>read and keep this carton for important information on safe use</li>
            <li>store between 68&deg;F to 77&deg;F (20&deg;C to 25&deg;C)</li>
            <li>keep out of the light</li>
          </ul>
        </div>
      </div>

      <div id="inactive-ingredients" className="dfl-section">
        <h2 className="dfl-sub-header">Inactive ingredients</h2>
        <span>calcium carbonate, ferric oxide yellow, fumaric acid, 
          hydroxypropylcellulose, low substituted hydroxypropylcellulose, magnesium stearate, mannitol</span>
      </div>

      <div id="questions" className="dfl-section">
        <h2 className="dfl-sub-header">Questions or comments?</h2>
        <span>call 1 xxx-xxx-xxxx   M-F 8-5pm EST</span>
      </div>
    </div>
  )
}
