import { TitleInfo } from "../MoreInfo"
import './Banner.css';


export function BannerWithOptions({ text, text2, style, list, modal, children, inline=false }) {
  const styleLink = {}

  if (!inline) {
    styleLink.marginTop = "5px"
  }

  return (
    <section className="assessment-BannerWithOptions general-banner" style={style}>
      <span>{text}</span>
      {!inline || (inline && text2) && <br />}
      {text2 && <span>{text2}</span>}

      {modal && children && (
        inline
          ? (
              <div style={{ display: "inline-flex", marginLeft: "0.5rem"}}>
                <TitleInfo children={children} styleLink={styleLink} />
              </div>
            )
          : <TitleInfo children={children} styleLink={styleLink} />
      )}
 
      {list && (
        <div className="assessment-bannerList">
          <ul>
            {list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}