import './AdbuList.css'

const adbuTranslation = {
  heart_attack: "You have had a heart attack",
  stroke: "You have had a stroke",
  heart_surgery_or_procedure: "You have had a heart surgery or heart procedure",
  breathlessness_with_activity: "You have had breathlessness or chest pain with activity",
  dizziness_fainting: "You have had dizziness or fainting when you stand up",
  unexplained_indigestion: "You have had unexplained indigestion",
  angina_or_chest_pain: "You have had angina or chest pain",
  irregular_heartbeat: "You have had irregular heartbeat",
  heart_valve_issue: "You have had heart valve issues ",
  uncontrolled_high_blood_pressure: "You have had uncontrolled high blood pressure",
  low_blood_pressure: "You have had low blood pressure",
  sudden_loss_of_vision: "You have experienced sudden loss of vision",
  sudden_loss_of_hearing: "You have experienced sudden loss of hearing",
  sickle_cell_anemia: "You have had sickle cell anemia",
  multiple_myeloma: "You have had multiple myeloma",
  leukemia: "You have had leukemia",
  bleeding_problems: "You have had bleeding problems",
  stomach_ulcers: "You have had stomach ulcers",
  erectionLongerThanFourHours: "You have had an erection longer than four hours",
  otherEDMedication: "You are using another ED treatment",
  oldAge: "You are 75 years old or older",
  enlargedProstate: "You take a prescription medicine for an enlarged prostate (BPH)",
  adbuPeyronies: "You have a deformed penis shape (Peyronie's Disease)",
  highBloodPressure: "You are currently taking a medicine to control your blood pressure",
}

function AdbuList({ answers }) {
  const flags = {
    oldAge: answers.age >= 75,
    adbuQuestions1: answers.adbuQuestions1.length !== 0,
    adbuQuestions2: answers.adbuQuestions2.length !== 0,
    adbuQuestions3: answers.adbuQuestions3.length !== 0,
    adbuQuestions4: answers.adbuQuestions4.length !== 0,
    erectionLongerThanFourHours: answers.erectionLongerThanFourHours === "yes",
    adbuNonCardiac1: answers.adbuNonCardiac1.length !== 0,
    adbuPeyronies: answers.adbuPeyronies === "yes",
    adbuNonCardiac2: answers.adbuNonCardiac2.length !== 0,
    adbuNonCardiac3: answers.adbuNonCardiac3.length !== 0,
    // adopbu questions that will be diverted here if the above are true
    otherEDMedication: answers.otherEDMedication === "yes",
    highBloodPressure: answers.highBloodPressure === "yes",
    enlargedProstate: answers.enlargedProstate === "yes",
  }
  const conditions = []
  if (flags.oldAge) {
    conditions.push("oldAge")
  }
  if (flags.adbuQuestions1) {
    conditions.push(...answers.adbuQuestions1)
  }
  if (flags.adbuQuestions2) {
    conditions.push(...answers.adbuQuestions2)
  }
  if (flags.adbuQuestions3) {
    conditions.push(...answers.adbuQuestions3)
  }
  if (flags.adbuQuestions4) {
    conditions.push(...answers.adbuQuestions4)
  }
  if (flags.adbuNonCardiac1) {
    conditions.push(...answers.adbuNonCardiac1)
  }
  if (flags.adbuNonCardiac2) {
    conditions.push(...answers.adbuNonCardiac2)
  }
  if (flags.adbuNonCardiac3) {
    conditions.push(...answers.adbuNonCardiac3)
  }
  if (flags.erectionLongerThanFourHours) {
    conditions.push("erectionLongerThanFourHours")
  }
  if (flags.adbuPeyronies) {
    conditions.push("adbuPeyronies")
  }
  if (flags.otherEDMedication) {
    conditions.push("otherEDMedication")
  }
  if (flags.highBloodPressure) {
    conditions.push("highBloodPressure")
  }
  if (flags.enlargedProstate) {
    conditions.push("enlargedProstate")
  }

  const formatConditions = (condition) => {
    return adbuTranslation[condition]
  }

  return (
    <ul className="adbuList">
      {conditions.length > 0 && conditions.map((condition, index) => <li key={index}>{formatConditions(condition)}</li>)}
    </ul>
  )
}

export default AdbuList