import React from 'react';
import './Title.css'

function Title({ text, variant = "primary", level="h1", id, ariaLevel }) {
  const Tag = level;

  const headingProps = level === 'div' 
    ? { role: 'heading', 'aria-level': ariaLevel }
    : {};

  return (
    <Tag className={`assessment-title ${variant}`} id={id} {...headingProps}>
      {text && <>
        {text.includes('Click all that apply.')
          ? text.split('Click all that apply.').map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index > 0 && <><br/><span>Click all that apply.</span></>}
              </React.Fragment>
            ))
          : text
        }
        </>}
    </Tag>
  );
}

export default Title;