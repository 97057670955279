import React, { useState, useEffect } from 'react'

import './NumericInput.css'

export function validateInput(value, minValue, maxValue) {
  // check if the value is empty or contains non-numeric characters
  if (!value.trim() || /[^0-9]/g.test(value)) {
    return { error: 'Please enter a valid number', hasError: true };
  }

  // check if the value starts with 0
  if (value.length > 1 && value[0] === '0') {
    return { error: 'Please enter a valid number', hasError: true };
  }

  // check if the value is greater than the max value
  if (maxValue && Number(value) > maxValue) {
    return { error: `Please enter a number less than ${maxValue}`, hasError: true };
  }

  // check if the value is less than the min value
  if (minValue && Number(value) < minValue) {
    return { error: `Please enter a number greater than ${minValue - 1}`, hasError: true };
  }

  return { error: null, hasError: false }; // No error
}

function NumericInput({
  id,
  name,
  label,
  placeholder = '',
  disabled,
  units,
  err,
  value = '',
  onEvent,
  minValue = 0,
  maxValue = 999,
  minLength,
  maxLength = 999,
  hideLabel,
}) {
  const [inputValue, setInputValue] = useState(value)
  const [error, setError] = useState(err);

  const handleOnChange = (e) => {
    // only allow numbers and a max
    const value = e.target.value.slice(0, maxLength);
    const { error, hasError } = validateInput(value, minValue, maxValue);

    setError(error);
    setInputValue(value);

    if(!hasError) {
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value });
    } else {
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: undefined })
    }
  }

  const handleOnFocus = () => {
    setError('');
    onEvent({ componentId: id, type: "INPUT_SELECTED" });
  }

  const handleOnBlur = (e) => {
    const value = e.target.value

    if (!value.trim()) {
      // capitalize the first letter of the field name
      e.target.name = e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1);
      setError(`${ e.target.name } field is required`);
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: undefined })
    }

    onEvent({ componentId: id, type: "INPUT_DESELECTED" })
  };

  useEffect(() => {
    if(value) {
      setInputValue(value)
    }
  }, [])

  useEffect(() => {
    setError(err);
  }, [err]);

  return (
    <div className={`assessment-numeric-input`} data-disabled={disabled}>
      <label htmlFor={id} 
        className={`assessment-numeric-input__label ${ hideLabel ? 'sr-only': '' }`}
      >
        { label }
      </label>

      <div>
        <input 
          id={id}
          name={ name || id }
          className={`assessment-numeric-input__input ${ !!error ? 'error' : '' }`}
          value={ inputValue }
          placeholder={ placeholder }
          disabled={ disabled }
          aria-invalid={ !!error }
          aria-valuemin={ minValue }
          aria-valuemax={ maxValue }
          aria-valuenow={ inputValue }
          onChange={ handleOnChange }
          onFocus={ handleOnFocus }
          onBlur={ handleOnBlur }
          role="spinbutton"
          data-1p-ignore
        />
        { error && <span className="assessment-numeric-input__error" aria-live="assertive">
            { error }
          </span>
        }
      </div>
    </div>
  )
}

export default NumericInput;
