// AssessmentContext.js
import React, { createContext, useEffect, useState, useContext } from 'react';
import { checkoutClient } from './client';


export const CheckoutContext = createContext({});

export const useCheckout = () => useContext(CheckoutContext);

export const CheckoutProvider = ({ children }) => {
  // checkout flow state
  const [index, setIndex] = useState(parseInt(sessionStorage.getItem('checkoutStep'), 10) || 0)
  const [mode, setMode] = useState('NORMAL')

  // [0] product selection
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(JSON.parse(sessionStorage.getItem('selectedProduct')) || {})

  // [1] shipping address
  const [address, setAddress] = useState(JSON.parse(sessionStorage.getItem('shippingAddress')) || {
    firstname: '',
    lastname: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  })
  const [selectedAddress, setSelectedAddress] = useState({})

  // [2] shipping method (hard-coded shipping options for nw)
  const shippingOptions = [{
    id: "3to5",
    description: "Ground 3-5 business days",
    price: 599,
  },
  {
    id: "1to2",
    description: "Expedited 1-2 business days",
    price: 1099,
  }]
  const [shippingMethod, setShippingMethod] = useState(JSON.parse(sessionStorage.getItem('shippingMethod')) || { id: '', value: '', description: '', price: 0 })

  // [3] payment method
  const [paymentIntentSecret, setPaymentIntentSecret] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [paymentMethodDetails, setPaymentMethodDetails] = useState({ brand: '', last4: '' })
  // need to rethink how to handle this
  const [paymentAddressSame, setPaymentAddressSame] = useState(Boolean(JSON.parse(sessionStorage.getItem('paymentAddressSame'))) || true)
  const [paymentMethodSelect, setPaymentMethodSelect] = useState('')
  const [cardInfo, setCardInfo] = useState({
    cardnumber: '',
    cardexpiry: '',
    cardcvc: '',
  })  
  const [billingAddress, setBillingAddress] = useState(JSON.parse(sessionStorage.getItem('billingAddress')) || {
    firstname: 'wut?',
    lastname: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  })

  const [cost, setCost] = useState(() => {
    const savedCost = sessionStorage.getItem('cost')
    return savedCost 
      ? JSON.parse(savedCost) 
      : { 
        loaded: false, 
        subtotal: 0, 
        shipping: 0, 
        tax: 159, 
        amount: 0 
      }
  })

  const [errors, setErrors] = useState({})

  useEffect(() => {
    checkoutClient.getProducts({})
      .then(resp => {
        setProducts(resp.products || [])
      })
      .catch(e => {
        console.error('failed to fetch products', e)
      })
  }, [])

  useEffect(() => {
    const savedStep = sessionStorage.getItem('checkoutStep')
    if (savedStep !== null) {
      setIndex(parseInt(savedStep, 10))
    }

    const savedAddress = JSON.parse(sessionStorage.getItem('shippingAddress'))
    if (savedAddress) {
      setAddress(savedAddress)
    }

    const savedAmount = sessionStorage.getItem('amount')
    const savedSubtotal = sessionStorage.getItem('subtotal')
    if (savedAmount) {
      setCost(prevCost => ({
        ...prevCost,
        amount: JSON.parse(savedAmount),
        subtotal: savedSubtotal ? JSON.parse(savedSubtotal) : prevCost.subtotal,
      }))
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem('checkoutStep', index.toString())
  }, [index])

  useEffect(() => {
    sessionStorage.setItem('selectedProduct', JSON.stringify(product))
  }, [product])

  useEffect(() => {
    // Store the updated address in session storage
    sessionStorage.setItem('shippingAddress', JSON.stringify(address))
  }, [address])

  useEffect(() => {
    // Store the updated billing address in session storage
    sessionStorage.setItem('billingAddress', JSON.stringify(billingAddress))
  }, [billingAddress])

  useEffect(() => {
    sessionStorage.setItem('shippingMethod', JSON.stringify(shippingMethod))
  }, [shippingMethod])

  useEffect(() => {
    sessionStorage.setItem('paymentMethod', paymentMethod)
  }, [paymentMethod])

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      if (paymentMethod) {
        try {
          const { id, last4, brand } = await checkoutClient.getPaymentMethod({ id: paymentMethod })
          setPaymentMethodDetails({ loaded: true, id, last4, brand })
        } catch (error) {
          console.error("Failed to fetch payment method:", error)
        }
      }
    }
    fetchPaymentMethod()
  }, [paymentMethod])

  useEffect(() => {
    sessionStorage.setItem('paymentAddressSame', JSON.stringify(paymentAddressSame))
  }, [paymentAddressSame])


  return (
    <CheckoutContext.Provider value={{ 
      index, setIndex,
      mode, setMode,
      products,
      product, setProduct,
      cost, setCost,
      address, setAddress,
      selectedAddress, setSelectedAddress,
      shippingOptions,
      shippingMethod, setShippingMethod,
      paymentIntentSecret, setPaymentIntentSecret,
      paymentMethod, setPaymentMethod,
      paymentMethodDetails, setPaymentMethodDetails,
      paymentAddressSame, setPaymentAddressSame,
      paymentMethodSelect, setPaymentMethodSelect,
      cardInfo, setCardInfo,
      billingAddress, setBillingAddress,
      errors, setErrors,
    }}>
      {children}
    </CheckoutContext.Provider>
  );
};