import React, { useState, useEffect } from "react"
import closecircle from "../img/closecircle.svg"

import "./WarningModal.css"


// Warning modal is displayed when the user clicks on either the logo or the browser back button.
function WarningModal({ handleLeave, handleContinue, title, text, btnLeaveText, btnContinueText }) {
  const [cooldownActive, setCooldownActive] = useState(true)

  // Adding cooldown effect to the buttons when accessing the screen
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCooldownActive(false)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div id="warning-modal" className="warning-modal"> 
      <div className="close-button-container">
        <button 
          className="close-button"
          disabled={cooldownActive}
          aria-disabled={cooldownActive}
          onClick={handleContinue}>
            <img src={closecircle} alt="" />
            <span className="sr-only">Close modal</span>
        </button>
      </div>

      { title && <h2 className="warning-modal-title">{title}</h2> }
      <p className="warning-modal-text">{text}</p>
      <div className="warning-modal-buttons">
        <button
          className="warning-button"
          aria-label="Leave page"
          aria-disabled={cooldownActive}
          disabled={cooldownActive}
          onClick={handleLeave}
        >
          {btnLeaveText}
        </button>
        <button
          className="warning-button"
          aria-label="Continue"
          aria-disabled={cooldownActive}
          disabled={cooldownActive}
          onClick={handleContinue}
        >
          {btnContinueText}
        </button>
      </div>
    </div>
  )
}

export default WarningModal