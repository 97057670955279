import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { client } from '../client'
import router from '../history'
import { maybeGetHistory } from '../reducers'
import LayoutScreen from '../components/Layout'
import AdbuList from './outcome/AdbuList'

import './Return.css'

export default function ReturnPage() {
  const { assessmentId } = useParams()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { state, outcome, answers, adbu, needsBp, lastAssessment, doctorAttestation, didAttest, purchased } = auth
  const returning = ["completed", "returning", "returningedit"].includes(state)

  const handleOnSubmit = async () => {
    if (purchased) {
      const res = await client.takeReassessment({
        specid: "3fa155b5-5dcc-4048-9ba2-9dbef17a3c4f",
        assessmentid: assessmentId,
      })

      router.replace(`/assessment/${res.assessmentid}`)
      return
    } 
    
    if (returning) {
      router.replace(`/assessment/${assessmentId}?returning=1`)
      return
    } 
    
    router.replace(`/assessment/${assessmentId}`)
  }

  useEffect(() => {
    dispatch(maybeGetHistory())
  }, [assessmentId])

  return <LayoutScreen context="returning" showProfile={true}>
    { lastAssessment && <div className='returning-container'>
      <h1>
        { purchased 
          ?  <>Welcome back.</>
          :  <>Welcome back. <br className=' md:hidden' />Nice to see you again.</>
        }
      </h1>

      <div className='content-body'>
        { (() => {
              if (!returning) {
                return (
                  <>
                    <p>We've saved your answers from your last visit.</p>
                    <p>
                      Please complete the rest of the health survey to see if
                      Stendra is right for you.
                    </p>
                  </>
                )
              }

              if (purchased) {
                return (
                  <>
                    <p>
                      Have you had any changes in your health since you last
                      took the online Stendra survey?
                    </p>
                    <p>
                      Please review your answers and click the edit button to
                      change anything that may be different from last time.
                    </p>
                  </>
                )
              }

              if (outcome === "ok") {
                return (
                  <p>
                    You are qualified to purchase Stendra! Complete check out
                    to buy Stendra today.
                  </p>
                )
              }
              
              if (needsBp && !adbu) {
                return (
                  <>
                    <p>You will need your recent blood pressure numbers.</p>
                    <p>Please enter your blood pressure numbers to complete your health survey.</p>
                  </>
                )
              }

              if (adbu && !needsBp) {
                return (
                  <>
                    <p>
                      Based on the information you were to ask a doctor if
                      Stendra is safe for you because:
                    </p>
                    <AdbuList answers={answers} />
                  </>
                )
              }

              if (adbu && needsBp) {
                return (
                  <>
                    <p>You will need your recent blood pressure numbers.</p>
                    <p>
                      Based on the information you were to ask a doctor if
                      Stendra is safe for you because:
                    </p>
                    <AdbuList answers={answers} />
                  </>
                )
              }
          })() }
      </div>

      <div className='button-container'>
        <button onClick={handleOnSubmit} className="btn secondary">
          Next
        </button>
      </div>
    </div> }
  </LayoutScreen>
}