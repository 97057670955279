import { useState } from "react"
import { useAssessment } from "../../AssessmentContext"

import AuthWrapper from '../../components/AuthWrapper'
import LayoutScreen from "../../components/Layout"
import OutcomeFlow from "./OutcomeFlow"
import SuccessFlow from "./SuccessFlow"

import "./Outcome.css"

function OutcomePage({ spec, state, setState, client, assessmentNumber }) {
  const { outcome } = state
  const { onEvent } = useAssessment()

  console.log("OutcomePage", state)
 
  const storedValue = sessionStorage.getItem("OutcomeFlow_showSignInScreen")
  const hasStoredValue = storedValue !== null ? JSON.parse(storedValue) : true
  const [showSignInScreen, setShowSignInScreen] = useState(hasStoredValue)
  
  // Check the role of the user
  const role = sessionStorage.getItem("role")
  const isClinicianUser = role.includes("clinician");

  const handleOutcomeOnSubmit = () => {
    setShowSignInScreen(false)
  }

  // sign in screen moved here from summary screen so that kickout can be calculated first
  // We do not display the sign in screen if the user is signed in as clinician
  if (showSignInScreen && !isClinicianUser) {
    return (
      <LayoutScreen>
        <div className="outcome-auth-wrapper">
            <AuthWrapper 
              outcomeOnSubmit={handleOutcomeOnSubmit}
              onSubmitassessmentState="outcome"
              inProgress={ true }
            />
        </div>
      </LayoutScreen>
    )
  }

  if (outcome === "ok") {
    return <SuccessFlow 
      state={state}
      client={client}
      onEvent={onEvent}
      assessmentNumber={assessmentNumber}
    />
  }

  return <OutcomeFlow
    spec={spec}
    state={state}
    setState={setState}
    client={client}
    onEvent={onEvent}
    assessmentNumber={assessmentNumber}
  />
}

export default OutcomePage