import React, { useContext, useEffect, useState } from "react"
import LayoutScreen from "../../components/Layout"
import ModalContext from "../../modal"
import { useAudit } from "../../audit"
import { DFL } from "../../components/DFL"

import { AssessmentConfirmingModal } from "../../components/AssessmentConfirmingModal"

export function DFLConfirmModal({ onConfirm, onGoBack }) {
  return (
    <AssessmentConfirmingModal
      handleClose={onGoBack}
      handleContinue={onConfirm}
      text="I confirm that I have read the Drug Facts Label."
    />
  )
}

function DFLScreen({ onRead, wrapInLayout = true }) {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false)
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({ type: "READ_DFL_CONFIRM_MODAL_DISPLAYED" })

  const handleOnConfirm = async () => {
    await onEvent({ type: "READ_DFL_CONFIRM_MODAL_CLICKED" })
    onRead()
    setModal()
  }

  const handleOnClose = () => {
    setModal()
  }

  const handleOnClick = () => {
    onEvent({ type: "READ_DFL_NEXT_CLICKED" })

    setModal(() => (
      <DFLConfirmModal
        onConfirm={handleOnConfirm}
        onGoBack={handleOnClose}
      />
    ))
  }

  useEffect(() => {
    const mainElement = document.querySelector('.layout-main');

    const handleScroll = () => {
      if (!mainElement) return;

      const scrollTop = mainElement.scrollTop;
      const scrollHeight = mainElement.scrollHeight;
      const clientHeight = mainElement.clientHeight;

      const scrollPercentage = scrollTop / (scrollHeight - clientHeight);
      const scrolledToBottom = scrollPercentage >= 0.98;

      setHasScrolledToBottom(scrolledToBottom);
    }

    mainElement?.addEventListener('scroll', handleScroll);

    return () => {
      mainElement?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const content = (
    <div id="outcome">
      <div className="dfl-content-wrapper">
        <p className="body-large">Be sure to read the Drug Facts Label (DFL) below.</p>
        <p className="body-large">
          This provides key information about how to use Avanafil OTC, including dose, warnings and side effects.
        </p>
        <DFL />
        <div className="dfl-btn-container">
          <button
            className="btn secondary"
            disabled={!hasScrolledToBottom}
            onClick={handleOnClick}>
            Next
          </button>
        </div>
      </div>
    </div>
  )

  return wrapInLayout 
    ? <LayoutScreen stickyFooter={true}>{content}</LayoutScreen> 
    : content
}

export default DFLScreen