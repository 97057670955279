import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { decodeAssessmentState } from "../../spec/utils"
import { useStateWithSessionStorage } from "../../hooks"

import AdbuScreen from "./AdbuScreen"
import AdopbuScreen from "./AdopbuScreen"
import DFLScreen from "./DFLScreen"
import LayoutScreen from "../../components/Layout"
import PurchaseScreen from "./PurchaseScreen"

function OutcomeFlow({ state, setState, client, assessmentNumber }) {
  const { answers } = state
  const { adbu, adopbu } = answers
  const { assessmentId } = useParams()

  const [showDFL, setShowDFL] = useStateWithSessionStorage("OutcomeFlow_showDFL", false)
  const [readDFL, setReadDFL] = useState(sessionStorage.getItem("OutcomeFlow_readDFL") || false)


  const handleReadDFL = () => {
    sessionStorage.setItem("OutcomeFlow_readDFL", true)
    setReadDFL(true)
    setShowDFL(false)
  }

	const onReturnEdit = (screenId, answers) => {
		return client
			.returningEdit({
				assessmentId,
				screenId,
				answers: JSON.stringify(answers),
			})
			.then((response) => {
				const nextState = decodeAssessmentState(response.state)
				setState(nextState)
			})
	}

  const handleAdopbuOnConfirm = async (answer) => {
    await onReturnEdit("AskedDoctorOrPharmacistScreen", {askedDoctorOrPharmacist: answer})
    if (answer === "yes") {
      setShowDFL(true)
    }
  }

  const handleAdbuOnConfirm = async (answer) => {
    await onReturnEdit("AskedDoctorScreen", {askedDoctor: answer})
    if (answer === "yes") {
      setShowDFL(true)
    }
  }

  const body = (() => {
    if (showDFL) {
      return <DFLScreen wrapInLayout={false} onRead={handleReadDFL} />
    }

    if (adopbu && !adbu) {
      if (!answers.askedDoctorOrPharmacist || answers.askedDoctorOrPharmacist === "no") {
        return (
          <AdopbuScreen
            answers={answers}
            onConfirm={handleAdopbuOnConfirm}
            setShowDFL={setShowDFL}
            handleReadDFL={handleReadDFL}
          />
        )
      } else if (answers.askedDoctorOrPharmacist === "yes" && readDFL) {
        return <PurchaseScreen wrapInLayout={false} outcomeOk={false} />
      }
    }

    if (adbu || adopbu) {
      if (!answers.askedDoctor || answers.askedDoctor === "no") {
        return (
          <AdbuScreen
            answers={answers}
            onConfirm={handleAdbuOnConfirm}
            setShowDFL={setShowDFL}
            handleReadDFL={handleReadDFL}
          />
        )
      } else if (answers.askedDoctor === "yes" && readDFL) {
        return <PurchaseScreen wrapInLayout={false} outcomeOk={false}/>
      }
    }
  })()

  return (
    <LayoutScreen context={`${ showDFL ? 'dfl': ''}`}>
      { body }
    </LayoutScreen>
  )
}

export default OutcomeFlow