import './PasswordStrengthIndicator.css'

function PasswordStrengthIndicator({ rules }) {
  return (
    <div className="password-strength-indicator">
      {[...Array(6)].map((_, i) => (
        <div key={i} className={`h-[6px] ${i < Object.values(rules).filter(value => value).length ? 'filled': ''}`} />
      ))}
    </div>
  )
}

export default PasswordStrengthIndicator