// AssessmentContext.js
import React, { createContext, useState, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom"
import { encodeScreenState, decodeAssessmentState } from './spec/utils';
import { client } from './client';
import petrosSpec from './v.json';

export const AssessmentContext = createContext({});

export const useAssessment = () => useContext(AssessmentContext);

export const AssessmentProvider = ({ children, initialState }) => {
  const navigate = useNavigate()
  const { assessmentId } = useParams()
  const defaultState = { state: "assessment", isTaking: true, index: 0, outcome: "", loading: true }
  const [state, setState] = useState(initialState || defaultState);
  const [spec, setSpec] = useState({ children: petrosSpec.children, loading: true });
  const [doctorAttestation, setDoctorAttestation] = useState("");
  const [assessmentNumber, setAssessmentNumber] = useState(-1);

  const clearAssessmentContext = () => {
    setState(initialState || defaultState)
    setSpec({ children: petrosSpec.children, loading: true })
    setDoctorAttestation("")
    setAssessmentNumber(-1)
  }

  const onSubmit = (answers) => {
    const encoded = encodeScreenState(answers)

    // need to sanitize assessmentId to ensure there is no "&assessmentState=outcome"
    const assessmentIdSanitized = assessmentId.split('&')[0]

    return client.submitScreen({assessmentid: assessmentIdSanitized, screenstate: encoded})
      .then(response => {
        const decoded = decodeAssessmentState(response.state)
        if(state.outcome === "") {
          setState({...state, ...decoded})
        }
      })
      .catch(e => console.error(e))
  }

	const onBack = () => {
		if (state.isKickout || state.isOutcome || state.state === "returningedit") {
			navigate("/")
			return
		}
    
		if (state && state.index === 0) {
			navigate("/")
			return
		}

		return client
			.goBack({ assessmentid: assessmentId })
			.then((res) => {
				const decoded = decodeAssessmentState(res.state)
				setState(decoded)
			})
			.catch((e) => console.error(e))
	}

	const onEvent = (e) => {
		return client
			.recordEvent({
				assessmentid: assessmentId,
				eventtype: e.type,
				data: JSON.stringify(e),
			})
			.catch((e) => console.error(e))
	}

  return (
    <AssessmentContext.Provider value={{ 
      assessmentId,
      spec, setSpec, 
      state, setState, 
      doctorAttestation, setDoctorAttestation, 
      assessmentNumber, setAssessmentNumber,
      onBack,
      onEvent,
      onSubmit,
      clearAssessmentContext,
    }}>
      {children}
    </AssessmentContext.Provider>
  );
};