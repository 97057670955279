import './AdbuList.css'

function AdopbuList({ answers }) {
  const flags = {
    otherEDMedication: answers.otherEDMedication === "yes",
    highBloodPressure: answers.highBloodPressure === "yes",
    enlargedProstate: answers.enlargedProstate === "yes",
  }
  const conditions = []
  if (flags.otherEDMedication) {
    conditions.push("You are using another ED treatment")
  }
  if (flags.highBloodPressure) {
    conditions.push("You are currently taking a medicine to control your blood pressure")
  }
  if (flags.enlargedProstate) {
    conditions.push("You take a prescription medicine for an enlarged prostate (BPH)")
  }

  return (
    <ul className="adbuList">
      {conditions.length > 0 && conditions.map((condition, index) => <li key={index}>{condition}</li>)}
    </ul>
  )
}

export default AdopbuList