import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { decodeAssessmentState } from "../spec/utils"
import NavBar from "./NavBar"
import ProgressBar from "./ProgressBar"
import Footer from "./Footer"
import "./Layout.css"

import { useAssessment } from "../AssessmentContext"

function LayoutScreen({
  state, setState,
  client,
  assessmentId,
  children,
  displayTextBtn = true,
  showBackButton = false,
  onBack,
  context, // assessment, dfl, checkout, kickout, etc.
}) {
  const navigate = useNavigate()
  const mainRef = useRef(null); 
  const { spec } = useAssessment()

	const handleOnBack = () => {
    if(context === 'checkout') {
      onBack()
      return
    }

    if(!state) {
      console.warn("No state found")
      navigate("/")
      return
    }

		if (state.isKickout || state.isOutcome || state.state === "returningedit") {
			navigate("/")
			return
		}

		if (state && state.index === 0) {
			navigate("/")
			return
		}

		client
			.goBack({ assessmentid: assessmentId })
			.then((res) => {
				const decoded = decodeAssessmentState(res.state)
				setState(decoded)
			})
			.catch((e) => console.error(e))
	}

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTo(0, 0);
    }
  }, [state]);

  // Check if we should display the progress bar
  const isAssessmentState = state?.state === 'assessment';
  const isNotSignUpScreen = spec && spec?.children[state?.index]?.id !== "SignUpScreen";
  const isNotPrivacyNoticeScreen = spec && spec?.children[state?.index]?.id !== "PrivacyNoticeScreen";
  const shouldShowProgressBar = isAssessmentState && isNotSignUpScreen && isNotPrivacyNoticeScreen;

  return <>
    <NavBar onBack={handleOnBack} showBackButton={showBackButton} displayTextBtn={displayTextBtn} />
    { shouldShowProgressBar && <ProgressBar current={state.index + 1} total={spec.children.length - 6} /> }

    <main className={`layout-main ${context} ${ spec?.children[state?.index]?.id || '' } ${ shouldShowProgressBar ? 'with-progress' : ""}`} ref={mainRef}>
      {children}
    </main>

    <Footer />
  </>
}

export default LayoutScreen