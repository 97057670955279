import { useState, useEffect } from 'react'
import Checkbox from '../../components/AssessmentComponents/Checkbox'
import { BillingAddress } from './BillingInformation'
import { useCheckout } from '../../CheckoutContext';

import "./PaymentInformation.css"

export const CardComponents = ({
    setPaymentMethodDetails,
    state, setState, 
    errors, setErrors 
  }) => {

  const [touched, setTouched] = useState({
    cardnumber: false,
    cardexpiry: false,
    cardcvc: false
  })

  const handleErrors = ({ name, value }) => {
    const newErrors = { ...errors };
  
    // Handle card number
    if (name === 'cardnumber') {
      if (!value.trim() || value.length < 16) {
        newErrors.cardnumber = 'Please enter a valid card number';
      } else {
        delete newErrors.cardnumber;
      }
    }

    // Handle card expiry
    if (name === 'cardexpiry') {
      if (!value.trim()) {
        newErrors.cardexpiry = 'Please enter a valid expiry date in the MM/YY format';
      } else if (value[2] && value[2] !== '/') {
        newErrors.cardexpiry = 'Please enter a valid expiry date in the MM/YY format';
      } else if (value.length === 5 && !checkExpirationDate(value)) {
        newErrors.cardexpiry = 'Expiry date cannot be in the past';
      } else {
        delete newErrors.cardexpiry;
      }
    }

    // Handle card CVC
    if (name === 'cardcvc') {
      if (!value.trim() || value.length < 3) {
        newErrors.cardcvc = 'Please enter a 3 or 4 digit CVC';
      } else {
        delete newErrors.cardcvc;
      }
    }
  
    setErrors(newErrors);
  }

  const update = (e) => {
    let { name, value } = e.target;
  
    const maxLengths = {
      cardnumber: 16,
      cardexpiry: 5,
      cardcvc: 4,
    };

    if (name === 'cardnumber' || name === 'cardcvc') {
      value = e.target.value.replace(/[^0-9]/g, '');
    }


  
    if (name === 'cardexpiry') {
      // card expiry value should allow only numbers and '/'
      value = value.replace(/[^0-9/]/g, '');

      value = value.slice(0, maxLengths[name]);
    } else if (maxLengths[name]) {
      value = value.slice(0, maxLengths[name]);
    }
  
    // Merging new state with the existing state
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  
    // Handle errors
    handleErrors({ name, value });
  
    // Store in sessionStorage
    sessionStorage.setItem('paymentDetails', JSON.stringify({
      ...state,
      [name]: value,
    }));
  };

  const handleFocus = (e) => {
    const { name } = e.target
    setTouched({ ...touched, [name]: true })
  }

  const handleBlur = (e) => {
    let { name } = e.target;
    let value = e.target.value.replace(/[^0-9]/g, '');

    // if field is empty set a required error
    if (name === 'cardnumber') {
      if (!value.trim()) {
        setErrors({ ...errors, cardnumber: 'Card number is required' });
      }
    }

    if (name === 'cardexpiry') {
      if (!value.trim()) {
        setErrors({ ...errors, cardexpiry: 'Expiration date is required' });
      }
    }

    if (name === 'cardcvc') {
      if (!value.trim()) {
        setErrors({ ...errors, cardcvc: 'CVC is required' });
      }
    }
  }

  // Note: For human factors testing, we need to check if `checkCVCCode` has either 3 or 4 digits
  // and if `checkExpirationDate` has the MM/YY format and the input date is not past the current date.
  // TODO: Both of these would be replaced with Stripe's components validation
  const checkExpirationDate = number => {
    let parts = number.split("/")

    // Show error if more than 5 chars
    if (number.length > 5 || parts[0]?.length > 2 || parts[1]?.length > 2) return false

    // Assuming current date
    let currentDate = new Date()
    let currentYear = currentDate.getFullYear()
    let currentMonth = currentDate.getMonth() + 1 // January is 0 in JavaScript
    
    // Parse the input
    let expiryYear = parseInt(parts[1]) + 2000 // Assuming YY means 20YY
    let expiryMonth = parseInt(parts[0])

    const isValidMonth = expiryMonth <= 12
    const isCurrentYear = expiryYear === currentYear
    const isValidExpiryYear = expiryYear >= currentYear

    const isValidExpiryDate = isValidMonth && isValidExpiryYear && (isCurrentYear ? expiryMonth >= currentMonth : isValidMonth)

    return isValidExpiryDate
  }

  const determineCardBrand = (number) => {
    // Remove any non-digit characters and trim any leading/trailing spaces
    number = number.replace(/\D/g, '').trim();
  
    // Determine the card brand using a switch statement
    switch (number[0]) {
      case "3":
        return "amex";
      case "4":
        return "visa";
      case "5":
        return "mastercard";
      case "6":
        return "discover";
      default:
        return null;
    }
  };

  useEffect(() => {
    const initialCardDetails = JSON.parse(sessionStorage.getItem('paymentDetails')) || { cardnumber: '', cardexpiry: '', cardcvc: '' }
    if (initialCardDetails) {
      setState({...state, ...initialCardDetails})
    }
  }, [])

  useEffect(() => {
      if (state.cardnumber.length >= 16) { // Check if the card number is sufficiently long
          const brand = determineCardBrand(state.cardnumber)
          const last4 = state.cardnumber.slice(-4);
          setPaymentMethodDetails({ brand, last4 })
      }
  }, [state.cardnumber, setPaymentMethodDetails])
  
  return (
    <div className="grid grid-cols-1 grid-rows-3 md:grid-cols-6 md:grid-rows-2 gap-6 md:gap-8">
        <label className={`form-group ${ touched.cardnumber && !!errors.cardnumber ? 'error' : '' } md:col-span-6`} htmlFor="cardnumber">
          <span className="required">Card Number</span>

          <div className='relative'>
            <input
              type="text"
              id="cardnumber"
              name="cardnumber"
              value={state.cardnumber}
              onChange={update}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!errors.cardnumber}
              autoComplete='cc-number'
            />

            <span className="absolute right-0 p-3 h-[54px] space-x-2">
              <i className={`amex-card-icon h-full ${!state.cardnumber || state.cardnumber.startsWith(3) ? 'inline' : 'hidden'}`} data-testid="card-amex"></i>
              <i className={`visa-card-icon h-full ${!state.cardnumber || state.cardnumber.startsWith(4) ? 'inline' : 'hidden'}`} data-testid="card-visa"></i>
              <i className={`mastercard-icon h-full ${!state.cardnumber || state.cardnumber.startsWith(5) ? 'inline' : 'hidden'}`} data-testid="card-mastercard"></i>
            </span>


            { touched.cardnumber && (
              <>
                { errors.cardnumber && (
                  <span className="error-text">{ errors.cardnumber }</span>
                )}
              </>
            )}
          </div>
        </label>

        <label className={`form-group ${ touched.cardexpiry && !!errors.cardexpiry ? 'error' : '' } md:col-span-3`} htmlFor="cardexpiry">
          <span className="required">Expiration Date (MM/YY)</span>

          <div>
            <input
              type="text"
              id="cardexpiry"
              name="cardexpiry"
              value={state.cardexpiry}
              onChange={update}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!errors.cardexpiry}
              autoComplete='cc-exp'
            />

            { touched.cardexpiry && (
              <>
                { errors.cardexpiry && (
                  <span className="error-text">{ errors.cardexpiry }</span>
                )}
              </>
            )}
          </div>
        </label>

        <label className={`form-group ${ touched.cardcvc && !!errors.cardcvc ? 'error' : '' } md:col-span-3`} htmlFor="cardcvc">
          <span className="required">CVC</span>

          <div>
            <input
              type="text"
              id="cardcvc"
              name="cardcvc"
              value={state.cardcvc}
              onChange={update}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!errors.cardcvc}
              autoComplete='cc-csc'
            />

            { touched.cardcvc && (
              <>
                { errors.cardcvc && (
                  <span className="error-text">{ errors.cardcvc }</span>
                )}
              </>
            )}
          </div>
        </label>
    </div>
  )
}

export function PaymentMethod({ onSubmit }) {
  const { 
    cardInfo, setCardInfo,
    billingAddress, setBillingAddress, 
    paymentAddressSame, setPaymentAddressSame,
    setPaymentMethodDetails,
    errors, setErrors 
  } = useCheckout()

  const [state, setState] = useState({
    ...cardInfo,
    ...billingAddress,
  })

  const handleOnSubmit = (e) => {
    e.preventDefault()

    setCardInfo({
      cardnumber: state.cardnumber,
      cardexpiry: state.cardexpiry,
      cardcvc: state.cardcvc,
    })

    if(!paymentAddressSame) {
      setBillingAddress({
        firstname: state.firstname,
        lastname: state.lastname,
        line1: state.line1,
        line2: state.line2,
        city: state.city,
        state: state.state,
        zip: state.zip,
      })
    }
    onSubmit()
  }

  const onCheck = (isSame) => {
    setPaymentAddressSame(isSame)

    // TODO: this should wipe form state too

    if (isSame) {
      // remove billing address-related errors, firtname, lastname, line1, city, state, zip
      const newErrors = { ...errors }
      delete newErrors.firstname
      delete newErrors.lastname
      delete newErrors.line1
      delete newErrors.city
      delete newErrors.state
      delete newErrors.zip

      sessionStorage.removeItem('billingAddress')
      setErrors(newErrors)
    }
  }

  const enableButton = () => {
    const cardFieldsValid = 
      (state.cardnumber || '').trim().length > 0 
      && (state.cardexpiry || '').trim().length === 5
      && (state.cardcvc || '').trim().length > 0;
  
    const addressFieldsValid = 
      (state.firstname || '').trim().length > 0 
      && (state.lastname || '').trim().length > 0 
      && (state.line1 || '').trim().length > 0 
      && (state.city || '').trim().length > 0 
      && (state.state || '').trim().length > 0 
      && (state.zip || '').trim().length > 0;
  
    const noErrors = Object.keys(errors).length === 0;
  
    // If payment address is the same, only check card fields
    
    if (paymentAddressSame) {
      return !(cardFieldsValid && noErrors);
    } else {
      return !(cardFieldsValid && addressFieldsValid && noErrors);
    }
  }

  return (
    <div>
      <h1 className="screen-title">
        Payment Information
      </h1>
      <p className='screen-step'>(Step: 4/6)</p>
      <p className="info-error-text">* Field is required</p>

      <form className='space-y-6 md:space-y-8' onSubmit={handleOnSubmit}>
        <div>
          <Checkbox 
            title="Billing address is same as shipping address"
            value="same"
            variant="primary"
            className="same-address-checkbox p-0"
            onCheck={onCheck}
            checked={paymentAddressSame}
          />

          {!paymentAddressSame && (
            <div>
              <BillingAddress 
                state={state}
                setState={setState}
                errors={errors}
                setErrors={setErrors}
              />

              <hr className="components-divider"/>
            </div>
          )}
        </div>

        <CardComponents 
          setPaymentMethodDetails={setPaymentMethodDetails}   
          state={state}
          setState={setState}
          errors={errors}
          setErrors={setErrors}
        />

        <button 
          className="btn secondary col-span-4" 
          type="submit"
          disabled={enableButton()}
        >
          Preview Order
        </button>
      </form>
    </div>
  )
}

