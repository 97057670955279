import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from 'react-toastify'
import { useAudit } from "../../audit"

import { ClinicianCompletionToast } from '../../components/ClinicianToast'
import LayoutScreen from "../../components/Layout"
import RadioGroup from "../../components/AssessmentComponents/RadioGroup"

function PurchaseScreen({ outcomeOk=true, wrapInLayout = true }) {
  const { assessmentId } = useParams()
  const navigate = useNavigate()
  const [currentScreen, setCurrentScreen] = useState("PurchaseScreen")
  const [selectedOption, setSelectedOption] = useState("")
  const onEvent = useAudit({ type: "PURCHASE_SCREEN_DISPLAYED" })

  const role = sessionStorage.getItem("role") || "user"
  const isClinicianUser = role.includes("clinician")

  const handleOptionChange = (option) => {
    setSelectedOption(option.newValue)
    onEvent({ type: "UPDATED_PURCHASE_OPTION", newValue: option.newValue })
  }

  const handleNextClick = () => {
    const state = { shouldSkipPrompt: true }
    if (selectedOption === "buy-in-store") {
        navigate(`/qr/${assessmentId}` + window.location.search, { state })
    } else if (selectedOption === "buy-online") {
        navigate(`/checkout/${assessmentId}`, { state })
    }
  }
  
  useEffect(() => {
    // Show the toast if the user is a clinician and the outcome is OK
    if (outcomeOk && isClinicianUser) {
      toast(<ClinicianCompletionToast completedAssessment={true}/>, {autoClose: false, closeOnClick: false, closeButton:false, draggable: false})
    }
  }, [outcomeOk])

  useEffect(() => {
    window.scrollTo(0, 0)
    // Set the current screen state when the component is mounted
    setCurrentScreen("PurchaseScreen")
  }, [])

  const content = (
    <div className="purchase-content">
      <div className="body-large">Based on your answers, Avanafil OTC is appropriate for you. Please use as directed.</div>

      <div className="radio-list-wrapper">
        <RadioGroup id="purchase-options" 
          options={[
            { value: "buy-online", text: "Buy Online" },
            { value: "buy-in-store", text: "Buy in Store" },
          ]} 
          value={selectedOption} 
          onEvent={handleOptionChange} 
        />
      </div>

      <button 
        className="btn secondary" 
        onClick={handleNextClick} 
        disabled={isClinicianUser || !selectedOption}
      >
        Next
      </button>
    </div>
  )

  return wrapInLayout 
    ? <LayoutScreen>{content}</LayoutScreen> 
    : content
}

export default PurchaseScreen