import React from "react"
import { Link } from "react-router-dom"
import LayoutScreen from "../../components/Layout"
import errorCodeImg from "../../img/408.svg"
import "./ErrorPages.css"

function RequestTimeout() {
  return (
    <LayoutScreen>
      <div className="error-page-content request-timeout">
        <img src={errorCodeImg} alt="request timeout illustration" />

        <h1 role="alert">
          Your request took too long.
        </h1>

        <p>
          Please check your connection or try again.
        </p>

        <Link to="/" className="btn secondary text-center" replace={true}>
          Back to Homepage
        </Link>
      </div>
    </LayoutScreen>
  )
}

export default RequestTimeout
