import { useContext, useEffect, useState } from "react"
import ModalContext from "../../modal"
import { useAudit } from "../../audit"

import AdbuList from "./AdbuList"
import { AssessmentConfirmingModal } from "../../components/AssessmentConfirmingModal"
import Button from "../../components/AssessmentComponents/Button"
import KickoutScreen from "../../components/Screens/KickoutScreen"

import adbu from "../../img/adbu.png"

export function ADBUConfirmModal({ onConfirm, onGoBack }) {
  return (
    <AssessmentConfirmingModal
    handleClose={onGoBack}
    handleContinue={onConfirm}
    text="I confirm I have talked to a doctor, and they said it is appropriate for me to use."
  />
  )
}

function AdbuScreen({ answers, onConfirm }) {
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({ type: "OUTCOME_ADBU_DISPLAYED" })
  const [showKickout, setShowKickout] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showKickout])

  if (showKickout) {
    return <KickoutScreen id="kickout-id" onEvent={onEvent}>
      <div className="outcome-content">
        <img className="h-[100px] w-[100px] md:h-[150px] md:w-[150px] mx-auto mb-6" src={adbu} alt="" />

        <h1>Based on your answers, you must speak with a doctor before purchasing Avanafil OTC.</h1>

        <p>If a doctor says it is appropriate for you to use Avanafil OTC, please come back to purchase.</p>
      </div>
    </KickoutScreen>
  }

  const handleOnConfirm = async () => {
    // answers.askedDoctor = "yes"
    await onConfirm("yes")
    setModal()
  }

  const handleCloseModal = () => {
    setModal()
  }

  const handleYes = async () => {
    setModal(() => (
      <ADBUConfirmModal
        onConfirm={handleOnConfirm}
        onGoBack={handleCloseModal}
      />
    ))
  }

  const handleNo = async () => {
    await onConfirm("no")
    setShowKickout(true)
  }

  return (
    <div className="outcome-content">
      <img className="h-[100px] w-[100px] md:h-[150px] md:w-[150px] mx-auto mb-6" src={adbu} alt="" />
      <p className="content">
        Based on your answers, you should talk to a doctor about whether Avanafil OTC is appropriate for you to use because:
      </p>
      <AdbuList answers={answers} />
      <p className="content">Has a doctor said it is appropriate for you to use Avanafil OTC?</p>
      <div className="assessment-buttons-container">
        <Button className="primary outline rect" onClick={handleYes}>
          Yes
        </Button>
        
        <Button className="primary outline rect" onClick={handleNo}>
          No
        </Button>
      </div>
    </div>
  )
}

export default AdbuScreen