import React from 'react'

import './ProgressBar.css'

const ProgressBar = ({ current, total }) => {
    const progress = total > 0 ? Math.min(((current / total) * 100).toFixed(2), 100) : 100;
    const progressText = `${parseFloat(progress).toFixed(2)}%`;

    return (
        <div
            className="progress"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuetext={progressText}
            aria-label="Progress"
            role="progressbar"
        >
            <div className="inner-bar" style={{ '--progress': progress }} />
        </div>
    );
};

export default ProgressBar;