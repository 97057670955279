import React from "react"
import { Link } from "react-router-dom"
import LayoutScreen from "../../components/Layout"
import errorCodeImg from "../../img/502.svg"
import "./ErrorPages.css"

function BadGateway() {
  return (
    <LayoutScreen>
      <div className="error-page-content bad-gateway">
        <img src={errorCodeImg} alt="bad gateway illustration" />
        
        <h1 role="alert">
          We encountered an issue while trying to process your request.
        </h1>

        <Link to="/" className="btn secondary text-center" replace={true}>
          Back to Homepage
        </Link>
      </div>
    </LayoutScreen>
  )
}

export default BadGateway
