import Product from '../../components/Product';
import { useCheckout } from '../../CheckoutContext';
import { calculateTotalCost } from '../../components/CheckoutComponents'

import './SelectProduct.css';

function SelectProduct({ onSubmit }) {
  const { 
    products, 
    product, setProduct,
    cost, setCost,
  } = useCheckout();
  
  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  }

  const handleOnSelect = (id) => {
    // find the selected product
    const selectedProduct = products.find(p => p.id === id)

    setProduct(selectedProduct)
    setCost({
      ...cost,
      subtotal: selectedProduct.price,
      amount: calculateTotalCost(selectedProduct.price, cost.shipping, cost.tax),
    })
    sessionStorage.setItem('subtotal', JSON.stringify(selectedProduct.price))
    sessionStorage.setItem('amount', JSON.stringify(calculateTotalCost(selectedProduct.price, cost.shipping, cost.tax)))
  }

  return <div className="select-product-container">
    <h1 className="screen-title">
      Select Product
    </h1>
    <p className='screen-step'>(Step: 1/6)</p>

    <form className='space-y-6 md:space-y-8' onSubmit={handleOnSubmit}>
      <div className="products space-y-6 md:space-y-8" role="radiogroup">
        {products.map(p => <Product 
          key={p.id} 
          name="product" 
          value={p.id} 
          text={p.description} 
          price={p.price} 
          checked={p.id === product?.id} 
          onChange={handleOnSelect}
        />)}
      </div>

      <button 
        className="btn secondary" 
        type="submit" 
        disabled={!product?.id}>
        Next</button>
    </form>
  </div>
}

export default SelectProduct;