import React, { Component }  from 'react'
import ReactModal from 'react-modal';

const ModalContext = React.createContext(null)

export function CloseButton({ buttonStyling="unstyled", disabled=false, onClick }) {
  return (
    <button 
      disabled={disabled}
      className={`${buttonStyling} primary focusable`}
      onClick={onClick ? () => onClick() : null}
    >
      <span className="sr-only">Close</span>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.742445 2.30104C0.332404 1.891 0.332403 1.22619 0.742444 0.816153V0.816153C1.15248 0.406114 1.81729 0.406114 2.22733 0.816153L14.1064 12.6952C14.5165 13.1052 14.5165 13.7701 14.1064 14.1801V14.1801C13.6964 14.5901 13.0316 14.5901 12.6215 14.1801L0.742445 2.30104Z"
          fill="#001e46"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6247 0.816662C13.0348 0.406622 13.6996 0.406621 14.1096 0.816661V0.816661C14.5197 1.2267 14.5197 1.8915 14.1096 2.30154L2.23055 14.1806C1.82051 14.5906 1.1557 14.5907 0.745661 14.1806V14.1806C0.335621 13.7706 0.335621 13.1058 0.745661 12.6957L12.6247 0.816662Z"
          fill="#001e46"
        />
      </svg>
    </button>
  )
}

export function Modal({children, onRequestClose, showCloseButton=true, ...rest}) {
  return (
    <ReactModal onRequestClose={onRequestClose} {...rest}>
      { showCloseButton && (
        <div style={{position: "absolute", right: "16px", top: "16px"}}>
          <CloseButton onClick={onRequestClose}/>
        </div>
      )}
      {children}
    </ReactModal>
  )
}

export class ModalProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stack: [],
    }
  }

  setModal = modal => {
    this.setState(state => {
      const { stack } = state
      if (!modal) {
        const [_current, ...rest] = stack
        return {stack: rest}
      }
      return {stack: [modal, ...stack]}
    })
  }


  render() {
    const { stack } = this.state
    const isModalOpen = stack.length > 0

    const closeModal = () => this.setModal()

    return (
      <>
        <ModalContext.Provider value={this.setModal}>
          { this.props.children }
        </ModalContext.Provider>

        <Modal className="modal" overlayClassName="overlay" isOpen={isModalOpen} onRequestClose={closeModal}>
          { isModalOpen && stack[0]() }
        </Modal>
      </>
    )
  }
}

export default ModalContext
