import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams, Link } from "react-router-dom"
import router from "../history"
import { useAssessment }  from "../AssessmentContext"

// Local dependencies and utilities
import { authClient } from "../client"
import { assessmentStarted, setAssessmentNumber } from "../reducers"
import Footer from "../components/Footer"
import landingPageImage from "../img/landing-page-image.png"
import StendraLogo from "../img/avanafil-logo.svg"

// styles
import "./LandingPage.css"

function LandingPage({ client }) {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const clinician = searchParams.get("clinician")
  const invite = searchParams.get('invite')
  const [participantID, setParticipantID] = useState(null)
  const { clearAssessmentContext } = useAssessment()

  // Clear session data 😵‍💫
  const clearSessionData = () => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('screenState')
    sessionStorage.removeItem('participantID')
    sessionStorage.removeItem('assessmentID')
    sessionStorage.removeItem('dflRead')
    sessionStorage.removeItem('OutcomeFlow_showSignInScreen')
    sessionStorage.removeItem('continuewithEmail')
    sessionStorage.removeItem('paymentAddressSame')
    sessionStorage.removeItem('checkoutStep')
    sessionStorage.removeItem('selectedProduct')
    sessionStorage.removeItem('shippingAddress')
    sessionStorage.removeItem('shippingMethod')
    sessionStorage.removeItem('paymentMethod')

    authClient.clearToken()
  }

  useEffect(() => {
    clearAssessmentContext()
  }, [])

  const handleInvite = (invite) => {
    sessionStorage.setItem('invite', invite)
    sessionStorage.removeItem('participantID'); // Reset participant ID on new invite
    authClient.getToken()
      .catch(error => {
        console.error("Error getting token", error)
      })
  }

  const handleClinician = (clinicianToken) => {
    client.getClinicianAssessment({ token: clinicianToken })
      .then((resp) => {
        const { assessmentId, assessmentNumber } = resp
        dispatch(setAssessmentNumber(assessmentId, assessmentNumber))
      })
      .catch((error) => {
        console.error("Error getting clinician assessment", error)
      })
  }

  const handleStoredInvite = () => {
    const storedInvite = sessionStorage.getItem("invite")
    if (storedInvite && !invite && !clinician) {
      setSearchParams({ invite: storedInvite });
    }
  }

  useEffect(() => {
    console.log("LandingPage useEffect")
    clearSessionData()

    if (invite) {
      handleInvite(invite)
    }

    if (clinician) {
      handleClinician(clinician)
    }

    handleStoredInvite()

  }, [invite, clinician, dispatch, client])

  const start = async () => {
    if(!invite && !clinician) {
      console.warn("No invite token found")
      return
    }

    // check for clinician and set token if present
    if (clinician) {
      const { assessmentId, authToken } = await client.takeClinicianAssessment({
        token: clinician,
      })
      await authClient.setToken({ token: authToken })
      router.push(`/assessment/${assessmentId}`)
      return
    }

    if (invite) {
      try {
        await authClient.guestLogin()
        await dispatch(assessmentStarted())
      } catch (error) {
        console.error('Error starting assessment', error)
      }
    }
  }

  let version = ""
  if (process.env.NODE_ENV === "production") {
    version = process.env.REACT_APP_ROCK_VERSION_NUMBER
  } else {
    version = process.env.NODE_ENV
  }

  return (
    <div className="welcome">
      <header className="welcome-header">
        <div className="header-container">
          <img src={StendraLogo} className="welcome-logo" alt="Stendra Logo" />
          <Link to="/sign-in" className="signin-button">
            Sign in
          </Link>
        </div>
      </header>

      <main className="main">
        <section className="body">
          <h1 className="landing-title">
            Welcome to the Avanafil OTC Self-Check
          </h1>
          <p>
            This self-check must be completed to determine if Avanafil OTC is
            appropriate for you to use. It is only for men 18 years of age and
            older. If the results of this self-check confirm your eligibility,
            you will be granted access to purchase Avanafil OTC.
          </p>
          <p className="legal">For investigational use only</p>
          <p className="version">App version: { version }</p>

        </section>

        <section>
          <div className="cta">
            <p>Everything in this self-check is secure and private. It is just for you.</p>
            <button onClick={start}>Begin</button>
            <img src={landingPageImage} alt="Landing page" />
          </div>

          <div className="signin-link">
            <Link to="/sign-in">
              Already have an account? Sign in
            </Link>
            <p className="version">App version: { version }</p>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  )
}

export default LandingPage