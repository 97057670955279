import { useEffect, useState } from "react";
import "./Footer.css";

export default function Footer() {
  const [participantID, setParticipantID] = useState(null);

  useEffect(() => {
    const id = sessionStorage.getItem('participantID');
    setParticipantID(id);
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="privacy">Privacy Notice</div>
        <div className="divider">|</div>
        <div className="phone">1-800-XXX-XXXX</div>

        {participantID && (
          <>
            <div className="divider">|</div>
            <div className="participant">Participant ID: {participantID}</div>
          </>
        )}
      </div>
    </footer>
  );
}