import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams, useNavigate, Link } from "react-router-dom"

import { googleAuth, appleAuth } from "./Auth"
import { assessmentStarted } from "../reducers"
import AuthWrapper from "./AuthWrapper"
import { authClient, client } from "../client"
import { useAssessment } from "../AssessmentContext"
import PasswordPopover from "./PasswordPopover"
import PasswordStrengthIndicator from "./PasswordStrengthIndicator"
import icons from "../icn/index"

import './SignUp.css'


export function verifyEmail(email) {
  return {
    valid: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email),
    len: email.length > 0 && email.length <= 60,
  }
}

export function verifyPassword(password) {
  return {
    number: /\d/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    minimum: password.length >= 8,
    maximum: password.length > 0 && password.length <= 64,
    commonWords: password.length > 0 && !/(password|123456|qwerty|letmein|welcome|monkey|football|abc123|123abc|admin|test|admin|user|login|root|master|superuser|super|password1|password123|password1234|password12345|password123456|password1234567|password12345678|password123456789|password1234567890)/i.test(password),
  }
}

function SignUp() {
  // const authed = useSelector((state) => state.auth.authed)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { assessmentId, state, onSubmit } = useAssessment()
  const [searchParams] = useSearchParams()
  const assessmentState = state.state || searchParams.get("assessmentState")


  // State object to manage the form fields and their validation
  const [formValid, setFormValid] = useState(false)
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState({
    email: {
      length: false,
      valid: false,
    },
    confirmEmail: false,
    password: {
      minimum: false,
      maximum: false,
      number: false,
      uppercase: false,
      lowercase: false,
      commonWords: false,
    },
    confirmPassword: false,
    submission: "",
  })
  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    confirmPassword: false,
  })
  const [showPasswordHelper, setShowPasswordHelper] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "email") {
      setEmail(value)
    }

    if (name === "confirmEmail") {
      setConfirmEmail(value)
    }

    if (name === "password") {
      setPassword(value)
    }

    if (name === "confirmPassword") {
      setConfirmPassword(value)
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible({
      ...passwordVisible,
      password: !passwordVisible.password
    })
  }

  const toggleConfirmPasswordVisibility = () => {
    setPasswordVisible({
      ...passwordVisible,
      confirmPassword: !passwordVisible.confirmPassword
    })
  }

  const handlePasswordFocus = () => {
    setShowPasswordHelper(true)
  }

  const handlePasswordBlur = () => {
    setShowPasswordHelper(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setFormValid(true)
    setError({ ...error, submission: false })

    try {
      const signInResult = await authClient.nativeCreateAccount(email, password)

      if (signInResult.status !== "ok") {
        console.error("Error during native sign-in: SignUpComponent", signInResult)
        return
      }

      if (!assessmentId) {
        dispatch(assessmentStarted())
        return
      }

      if (!!assessmentId && assessmentState === "assessment") {
        // need to submit the current assessment screen (which is the sign up/sign in) screen 
        // so that when we navigate back to the assessment, the user will be on the next screen
        await onSubmit({answers: state.answers})
      } 
      
      if (assessmentState === "outcome") {
        sessionStorage.setItem("OutcomeFlow_showSignInScreen", "false")
      }

      navigate(`/assessment/${assessmentId}`)

    } catch (err) {
      console.error("Error during native sign-in: SignUpComponent", err.message)
      setError({ ...error, submission: err.message })
      return
    }
  }

  // Retrieve the email from sessionStorage when the component mounts
  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email")
    if (storedEmail) {
      setEmail(storedEmail)
    }
  }, [])

  // useEffect to track errors and form field validity
  useEffect(() => { 
    setError({
      ...error,
      email: verifyEmail(email),
      confirmEmail: (confirmEmail.length && confirmEmail !== email),
      password: verifyPassword(password),
      confirmPassword: (confirmPassword.length && confirmPassword !== password)
    })

    setFormValid(
      email.length > 0 
      && confirmEmail.length > 0
      && password.length > 0 
      && confirmPassword.length > 0
      && Object.values(error.email).every(value => value)
      && Object.values(error.password).every(value => value)
      && confirmEmail === email
      && confirmPassword === password
    )
  }, [email, password, confirmEmail, confirmPassword])
  
  return (
    <div className="signup">
      <h1 className="sr-only">Create an account</h1>

      <form className="signup-form" onSubmit={handleSubmit}>
        <p className="text-xs md:text-base font-bold text-red-500">* Field is required</p>

        <label className={`form-group ${ error.submission.length > 0 || email.length > 0 && !Object.values(error.email).every(value => value) ? 'error' : '' }`} htmlFor="email">
          <span className="required">Email Address</span>

          <div>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="name@example.com"
              value={email}
              onChange={handleChange}
              aria-invalid={email.length > 0 && !Object.values(error.email).every(value => value)}
              autoComplete="email"
              autoCorrect="off"
            />

            { email.length > 0 && !Object.values(error.email).every(value => value)
              && <span aria-live="assertive">
                  Invalid Email address. Please enter a valid email in this format: example@gmail.com
                </span>
            }
          </div>
        </label>

        <label className={`form-group ${ error.submission.length > 0 || confirmEmail.length > 0 && error.confirmEmail ? 'error' : '' }`} htmlFor="confirmEmail">
          <span className="required">Confirm Email</span>

          <div>
            <input
              type="text"
              id="confirmEmail"
              name="confirmEmail"
              placeholder="name@example.com"
              value={confirmEmail}
              onChange={handleChange}
              aria-invalid={confirmEmail !== email}
              autoComplete="off"
              autoCorrect="off"
            />

            { error.confirmEmail === true && <span aria-live="assertive">Email and confirm email do not match. Please enter again</span> }
          </div>
        </label>

        <label className={`form-group ${ error.submission.length > 0 || password.length > 0 && !Object.values(error.password).every(value => value) ? 'error' : '' }`} htmlFor="password">
          <span className="required">Password</span>

          <div className="relative">
            <input
              type={passwordVisible.password ? "text" : "password"}
              id="password"
              name="password"
              placeholder="password"
              value={password}
              onChange={handleChange}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
              aria-invalid={!Object.values(error.password).every(value => value)}
              aria-describedby="password-rules"
              autoComplete="new-password"
              autoCorrect="off"
            />

            <div className="strength">
              {password.length > 0 && (
                !Object.values(error.password).every(value => value) 
                  ? <span>Weak</span>
                  : <img src={icons["green-check"]} className="h-5 w-5" alt="strong password" />
              )}
            </div>

            <button className="toggle-password" onClick={togglePasswordVisibility} type="button" >
              <img src={passwordVisible.password ? icons["eye"] : icons["eye-slash"]} alt="Toggle password visibility" />
            </button>

            { showPasswordHelper && (
              <PasswordPopover rules={error.password} />
            )}
          </div>

          {password.length > 0 && !Object.values(error.password).every(value => value) && (
            <PasswordStrengthIndicator rules={error.password} />
          )}
        </label>

        <label className={`form-group ${ error.submission.length > 0 || confirmPassword.length > 0 && confirmPassword !== password ? 'error' : '' }`} htmlFor="confirmPassword">
          <span className="required">Confirm Password</span>

          <div className="relative">
            <input
              type={passwordVisible.confirmPassword ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="password"
              value={confirmPassword}
              onChange={handleChange}
              aria-invalid={confirmPassword.length > 0 && confirmPassword !== password}
              autoComplete="new-password"
              autoCorrect="off"
            />
            <button className="toggle-password" onClick={toggleConfirmPasswordVisibility} type="button" >
              <img src={passwordVisible.confirmPassword ? icons["eye"] : icons["eye-slash"]} alt="Toggle confirm password visibility" />
            </button>
          </div>
          { confirmPassword.length > 0 && confirmPassword !== password && <span aria-live="assertive">Password and confirm password do not match. Please enter again</span> }
        </label>

        <button type="submit" disabled={ !formValid }>
          Create Account & Sign In
        </button>

        {error.submission ? <div className="sign-up-error">{error.submission}</div> : null}
      </form>

      <div className="divider">
        <p>Or</p>
      </div>

      <div className="signup-options">
        <div className="text-center md:text-[24px] py-4 md:p-0">
          <Link to={{ 
            pathname: assessmentId ? `/sign-in/${assessmentId}` : '/sign-in', 
            search: searchParams.toString() 
          }} replace>
            Have an account? Sign in
          </Link>
        </div>

        <AuthWrapper onSubmit={onSubmit} displayTitle={false} />
      </div>
    </div>
  )
}

export default SignUp