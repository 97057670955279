import React from "react"
import SignUp from "../components/SignUp"
import LayoutScreen from "../components/Layout"

export default function CreateAccount({ authClient }) {
  return (
    <LayoutScreen showProfile={false}>
      <SignUp />
    </LayoutScreen>
  )
}
