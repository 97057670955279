import Checkbox from "./Checkbox"

import "./Checkbox.css"

function CheckboxList({ id, options, none, onEvent, value, instructions }) {
  const isNoneChecked = value !== undefined && value.length === 0

  const onCheckRegular = (opt, checked) => {
    let newValue;
    if (checked) {
      const currentValues = value === undefined ? [] : value;
      newValue = [...currentValues, opt.value];
    } else {
      newValue = value.filter((v) => v !== opt.value);
      if (newValue.length === 0) {
        newValue = undefined;
      }
    }
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue });
  };

  const children = options.map((opt, i) => (
    <Checkbox
      {...opt}
      key={i}
      value={opt.value}
      onCheck={(checked) => onCheckRegular(opt, checked)}
      checked={value !== undefined && value.includes(opt.value)}
    />
  ))

  if (none) {
    const onCheckNone = (checked) => {
      let newValue
      if (checked) {
        newValue = []
      } else {
        newValue = undefined
      }
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue })
    }
    children.push(<Checkbox {...none} key={options.length} onCheck={onCheckNone} checked={isNoneChecked} />)
  }

  return <div className="checkbox-list">
    { instructions && <div className='checkbox-list-instructions'>
        { instructions }
    </div> }
    
    <div>
      {children}
    </div>
  </div>
}

export default CheckboxList