import React, { createElement, useState } from 'react'

import AssessmentReferenceNumber from "../../components/AssessmentReferenceNumber"
import DFLScreen from "./DFLScreen"
import LayoutScreen from "../../components/Layout"
import PurchaseScreen from "./PurchaseScreen"

function SuccessFlow({ state = { outcome: "" }, assessmentNumber }) {
  const [readDFL, setReadDFL] = useState(sessionStorage.getItem("dflRead") || false)

  const handleReadDFL = () => {
    setReadDFL(true)
    sessionStorage.setItem("dflRead", true)
  }

  return (
    <LayoutScreen context={`${ !readDFL ? 'dfl' : ''}`}>
      { readDFL 
          ? <PurchaseScreen wrapInLayout={false} /> 
          : <DFLScreen wrapInLayout={false} onRead={handleReadDFL} />
      }
      { readDFL && createElement(AssessmentReferenceNumber, { state, assessmentNumber}, [])}
    </LayoutScreen>
  )
}

export default SuccessFlow