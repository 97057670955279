import Product from '../../components/Product';
import { useCheckout } from '../../CheckoutContext';
import { calculateTotalCost } from '../../components/CheckoutComponents'

import "./ShippingMethod.css"

function ShippingMethod({ onSubmit }) {
  const { 
    shippingOptions, 
    shippingMethod, setShippingMethod,
    cost, setCost,
  } = useCheckout()

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  }

  const handleOnSelect = (id) => {
    const selectedShippingMethod = shippingOptions.find(o => o.id === id)

    const shippingCost = selectedShippingMethod.price
    setShippingMethod(selectedShippingMethod)
    const updatedAmount = calculateTotalCost(cost.subtotal, shippingCost, cost.tax)
    setCost({
      ...cost,
      shipping: shippingCost,
      amount: updatedAmount,
    })
    sessionStorage.setItem('amount', JSON.stringify(updatedAmount))
  }
  
  return <div className="shipping-method-container">
    <h1 className="screen-title">
      Shipping Method
    </h1>
    <p className='screen-step'>(Step: 3/6)</p>

    <form className='space-y-6 md:space-y-8' onSubmit={handleOnSubmit}>
      <div className="shipping-methods space-y-6 md:space-y-8" role="radiogroup">
        {shippingOptions.map(o => <Product 
          key={o.id} 
          name="shipping-method" 
          value={o.id} 
          text={o.description} 
          price={o.price} 
          checked={o.id === shippingMethod.id} 
          onChange={handleOnSelect}
        />)}
      </div>

      <button 
        className="btn secondary" 
        type="submit" 
        disabled={!shippingMethod?.id}>
          Next
        </button>
      </form>
  </div>
}

export default ShippingMethod;