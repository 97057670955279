import React from "react"
import Modal from "react-modal"
import modalClose from "../icn/modal-close.svg"
import "./Modal.css"

const modalStyle = {
  overlay: {
    backgroundColor: "var(--primary-focus)",
    zIndex: 5,
  },
  content: {
    background: "var(--tertiary)",
    maxHeight: "100vh",
    position: "absolute",
    overflowY: "auto",
  },
}

function GenericModal({
  isOpen,
  buttonText,
  children,
  onRequestClose,
  stickyButton,
  title,
}) {
  // Animation for sliding out the modal and then closing it.
  const handleAnimationModal = () => {
    const modal = document.getElementById("modal")
    modal.style.animationName = "slideout"
    setTimeout(onRequestClose, 490)
  }

  return (
    <Modal
      id="modal"
      style={{ ...modalStyle }}
      isOpen={isOpen}
      onRequestClose={handleAnimationModal}
      className={`sticky-button-modal wrapper-animation${isOpen ? "-active" : ""}`}
    >
      <div className="sticky-modal-header">
        { title && <h2>{title}</h2> }
        
        <button className="rounded-full" onClick={handleAnimationModal} aria-label="Close modal">
          <img src={modalClose} alt="" />
        </button>
      </div>
      <section className="flex-1 overflow-scroll">
        <div className="p-6 md:p-8">{children}</div>
      </section>

      {stickyButton && (
        <div className="p-6 bg-white">
          <button className="btn secondary block mx-auto" onClick={handleAnimationModal}>
            {buttonText}
          </button>
        </div>
      )}
    </Modal>
  )
}

export default GenericModal