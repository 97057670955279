import icons from "../icn/index"

import './PasswordPopover.css'

function PasswordPopover({ rules }) {
  const descriptions = {
    number: "Contain at least 1 number",
    uppercase: "Contain at least 1 uppercase letter",
    lowercase: "Contain at least 1 lowercase letter",
    minimum: "Be a minimum of 8 characters",
    maximum: "Be less than 65 characters",
    commonWords: "Not contain words such as password, your name, email or other common words",
  }

  return (
    <div id="password-rules" className="password-tooltip" aria-live="polite">
      <h2>Your password must:</h2>
      <ul>
        {Object.entries(rules).map(([rule, isValid]) => (
          <li key={rule}>
            {isValid ? <img src={icons["green-check"]} alt="Valid" /> : <img src={icons["red-x"]} alt="Invalid" />}
            <span>{descriptions[rule]}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PasswordPopover