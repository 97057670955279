export function nodeHasChildren(node) {
  return node !== undefined && node.children !== undefined && node.children.length > 0
}

export function getNodeById(root, id) {
  if (root === undefined) {
    return undefined
  }
  if (root.id === id) {
    return root
  }
  if (!nodeHasChildren(root)) {
    return undefined
  }
  for (let i = 0; i < root.children.length; i++) {
    let found = getNodeById(root.children[i], id)
    if (found) {
      return found
    }
  }
  return undefined
}

export function findChild(root, id) {
  if (!root) { return undefined }
  if (root.children) {
    for (const c of root.children) {
      const found = getNodeById(c, id)
      if (found) {
        return found
      }
    }
  }
  return undefined
}

// find the screen index containing the component with the id
export function findScreenIndex(spec, id) {
  if (!spec) { return -1 }
  if (spec.children) {
    for (let i = 0; i < spec.children.length; i++) {
      const found = findChild(spec.children[i], id)
      if (found) {
        return i
      }
    }
  }
  return -1
}

export function updateNodeById(root, id, update) {
  if (root === undefined) {
    return undefined
  }
  if (root.id === id) {
    return {...root, ...update}
  }
  if (!nodeHasChildren(root)) {
    return root
  }
  return {...root, children: root.children.map(c => updateNodeById(c, id, update))}
}

// returns the questions starting from root
export function questions(root, ret=[]) {
  walk(root, n => {
    if (n.id && n.type !== "Assessment" && n.type !== "Screen") {
      ret.push(n)
    }
    if (Array.isArray(n.options)) {
      for (const option of n.options) {
        if (option.id) {
          ret.push(option)
        }
      }
    }
  })
  return ret
}

export function index(spec, id) {
  for (let i = 0; i < spec.children.length; i++) {
    const s = spec.children[i]
    const found = questions(s).find((q) => q.id === id)
    if (found) {
      return i
    }
  }
  return -1
}

// calls callback on each node in the tree
export function walk(root, callback) {
  callback(root)
  if (Array.isArray(root.children)) {
    for (const c of root.children) {
      walk(c, callback)
    }
  }
}

// internal assessment state to protobuf assessment state
export function encodeScreenState(state) {
  const { answers } = state;
  return {...state, answers: JSON.stringify(answers ?? {})};
}

// protobuf assessment state to internal assessment state
export function decodeAssessmentState(pbstate) {
  return {
    index: pbstate.index,
    state: pbstate.state,
    kickoutReason: pbstate.kickoutreason,
    isCompleted: pbstate.iscompleted,
    completed: pbstate.completed,
    answers: pbstate.answers ? JSON.parse(pbstate.answers) : undefined,
    isTaking: pbstate.state === "assessment",
    isReviewing: pbstate.state === "editablesummary",
    isEditing: pbstate.state === "edit" || pbstate.state === "returningedit",
    isKickout: pbstate.state === "kickout",
    isOutcome: pbstate.outcome ? pbstate.outcome !== "" : false,
    outcome: pbstate.outcome,
    editIndex: pbstate.editindex,
  }
}

// validate questions starting from root and return any errors
export function validate(root, answers) {
  const errs = {}
  for (const q of questions(root)) {
    // yes/no buttons submit their answers at the same time the screen is submitted
    if (q.type === "YesNoButtons" || q.type === "PrivacyNotice") continue

    const answer = answers[q.id]
    if (answer === undefined) {
      errs[q.id] = "This question is required"
      continue
    }

    if (q.validation) {
      for (const validation of q.validation) {
        const fn = new Function(`return answer => ${validation.cond}`)()
        if (fn(answer)) {
          errs[q.id] = validation.err
          break
        }
      }
    }
  }
  return errs
}

export function findMetricValueForKey(metrics, key) {
  const screenIndices = Object.keys(metrics)
  for (let i = 0; i < screenIndices.length; i++) {
    const screenIndex = screenIndices[i]
    const metricsForScreen = metrics[screenIndex]
    const value = metricsForScreen[key]
    if (value !== undefined) {
      return value
    }
  }
  return undefined
}

// returns date in format MM/DD/YYYY
// export const formatDate = (dateString) => {
//   if (!dateString) return '';

//   const date = new Date(dateString);
//   if (isNaN(date.getTime())) return '';

//   return date.toLocaleDateString('en-US', {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit'
//   });
// };