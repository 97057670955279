import Radio from "./Radio";

import './RadioGroup.css'

function RadioGroup({ id, question, options, value, onEvent }) {
  const selected = value

  return (
    <div className="radio-list">
      { question && <p className='mb-2'>{ question }</p>}
      <div className="radio-group" role="radiogroup">
        { options.map((opt) => {
          const isSelected = selected === opt.value
          const handleSelect = () => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: opt.value})

          return <Radio
            name={ id }
            value={ opt.value }
            title={ opt.title }
            text={ opt.text }
            disabled={ opt.disabled }
            checked={isSelected}
            ariaLabel={ opt.ariaLabel }
            onChange={ handleSelect }
            key={ opt.value }
          />
        })}
      </div>
    </div>
  )
}

export default RadioGroup;