/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Link } from "react-router-dom";
import StendraLogo from "../img/avanafil-logo.svg";
import BackArrow from "../icn/back-arrow.svg"

import "./NavBar.css"

export function CooldownButton({ type, children, onClick, ...rest }) {
  const [cooldownActive, setCooldownActive] = useState(false);

  const { disabled, ...newRest } = rest;
  if (disabled !== undefined) rest = newRest;

  const handleClick = () => {
    setCooldownActive(true);
    onClick();

    setTimeout(() => {
      setCooldownActive(false);
    }, 1000);
  }

  return (
    <button className="back-button" disabled={cooldownActive || disabled} aria-disabled={cooldownActive || disabled} {...rest} onClick={handleClick}>
      {children}
    </button>
  );
}

function NavBar({ onBack, showBackButton = false, displayTextBtn }) {
  
  const handleOnBack = () => {
    onBack();
  }

  return <>
    <header className="header">
      <div className="header-container">
        <div className="flex items-center">
          { showBackButton && <CooldownButton onClick={handleOnBack}>
            <img src={BackArrow} alt="" />
            { displayTextBtn ? <span>Previous Question</span> : null }
          </CooldownButton> }
        </div>

        <Link to="/" className="logo-link" state={{ canNavigate: true }}>
          <img src={StendraLogo} className="logo" alt="Stendra Logo" />
        </Link>
      </div>
    </header>
  </>
}

export default NavBar
