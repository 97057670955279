import { useState, useContext, useEffect, Fragment } from "react"
import { questions, index } from "../spec/utils"
import icons from "../icn/index"
import "../css/Summary.css"
import ModalContext from "../modal"
import { decodeAssessmentState } from "../spec/utils"
import Button from "../components/AssessmentComponents/Button"
import { AssessmentConfirmingModal } from "../components/AssessmentConfirmingModal"
import { useAudit } from "../audit"

const summaryQuestion = {
  buyingForSelf: "Purchasing for yourself",
  difficulties: "Recurring difficulties getting or keeping an erection hard enough for sex",
  otherEDMedication: "Medicine for erectile dysfunction",
  sexAtBirth: "Sex at birth",
  age: "Age",
  nitrateQuestion: "Ever prescribed a nitrate medicine",
  popperQuestion: "Use “poppers”",
  hypertension: "Medicine for pulmonary hypertension",
  heartNotSafeForSex: "Not safe for you to have sex",
  heartFailure: "Heart failure",
  heart_attack: "Heart attack",
  stroke: "Stroke",
  heart_surgery_or_procedure: "Heart surgery",
  breathlessness_with_activity: "Breathlessness, chest discomfort or pain",
  dizziness_fainting: "Get dizzy or faint when you stand up",
  unexplained_indigestion: "Unexplained indigestion",
  angina_or_chest_pain: "Angina or chest pain",
  irregular_heartbeat: "Irregular heartbeat",
  heart_valve_issue: "Heart valve issues",
  uncontrolled_high_blood_pressure: "Uncontrolled high blood pressure",
  low_blood_pressure: "Low blood pressure",
  sudden_loss_of_vision: "Sudden loss of vision",
  sudden_loss_of_hearing: "Sudden loss of hearing",
  sickle_cell_anemia: "Sickle cell anemia",
  multiple_myeloma: "Multiple myeloma",
  leukemia: "Leukemia",
  bleeding_problems: "Bleeding problems",
  stomach_ulcers: "Stomach ulcers",
  highBloodPressure: "Medicine for blood pressure",
  antifungalMedication: "Itraconazole or Ketoconazole (antifungals)",
  antibiotics: "Clarithromycin or Telithromycin (antibiotics)",
  antidepressants: "Nefazodone (antidepressant)",
  hivMedication: "HIV medicine",
  severe_kidney_disease: "Severe kidney disease",
  severe_liver_disease: "Severe liver disease",
  retinitis_pigmentosa: "Retinitis pigmentosa",
  nonCardiacHealthConditions: "Non-Cardiac Health Conditions",
  avanafilAllergy: "Allergic reaction to Avanafil",
  erectionLongerThanFourHours: "Erection lasted longer than 4 hours",
  adbuPeyronies: "Deformed penis shape",
  enlargedProstate: "Medicine for enlarged prostate (BPH)",
}

const editOverride = {
  //diagnosedDepression: "depression",
}

export function displayList(answer, options = []) {
  if (answer.length === 0) {
    return "None of these"
  }

  const text = {}
  for (const opt of options) {
    text[opt.value] = opt.title
  }

  return answer.map((val) => text[val] || val).join(", ")
}

const formatting = {
  unsure: "Not Sure",
  no: "No",
  yes: "Yes",
}

export function ConfirmModal({ setModal, onConfirm }) {
  useAudit({ type: "CONFIRM_ANSWERS_CHECKED" })

  const onGoBack = () => setModal()

  return (
    <AssessmentConfirmingModal
      handleClose={onGoBack}
      handleContinue={onConfirm}
      text="I confirm that the information I have provided is truthful and accurate to the best of my knowledge."
    />
  )
}

// This gives individual options for checkbox lists their own line in the summary
export function makeFoldQuestions({ answers }) {
  return (xs, q) => {
    const { id, type, options } = q

    let answer = answers[id]

    if (type === "CheckboxList") {
      const answer = answers[id]

      const listQuestions = options
        .filter((x) => x.value !== "none")
        .map(({ value, title }) => {
          // Ensure answer is defined to avoid "Cannot read properties of undefined" error.
          const isAnsweredYes = answer && answer.indexOf(value) >= 0
          let formattedAnswer = isAnsweredYes ? "Yes" : "No"

          // Check if the value itself is "unsure" and format accordingly
          // I dont think this is necessary, but i dont dare remove it 🤷🏻‍♂️
          // Seems based off some weird assumption about unsure being the first option...idk?
          if (isAnsweredYes && value === "unsure") {
            formattedAnswer = formatting.unsure;
          }
          return {
            id: value,
            answer: formattedAnswer,
            questionId: id,
            title,
          }
        })

      return [...xs, ...listQuestions]
    } else if (type === "RadioGroup") {
      if (answer === "No") {
        answer = formatting.no
      } else if (answer === "Yes") {
        answer = formatting.yes
      } else if (answer === "unsure") {
        answer = formatting.unsure
      }
    } else if (Array.isArray(answer)) {
      answer = displayList(answer, options)
    } else if (options) {
      const opt = options.filter((opt) => opt.value === answer)
      if (opt.length) {
        answer = opt[0].text
        if (answer.includes("No")) {
          answer = formatting.no
        }
        if (answer.includes(formatting.unsure)) {
          answer = formatting.unsure
        }
      }
    }

    return [...xs, { id, answer }]
  }
}

function SummaryPage({ spec, answers, client, assessmentId, setState }) {
  const [summaryIds, setSummaryIds] = useState([])

  useEffect(() => {
    client
      .getSummary({ assessmentid: assessmentId })
      .then((response) => {
        setSummaryIds(response.summaryids)
      })
      .catch((e) => console.error(e))
  }, [assessmentId, client])

  const { repurchase } = answers
  const onEvent = useAudit({ type: "CONFIRM_ANSWERS_DISPLAYED" })
  const qs = questions(spec).filter((q) => q.type !== "virtual")

  const keyValues = qs.filter((q) => summaryIds.includes(q.id)).reduce(makeFoldQuestions({ questions: qs, answers }), [])

  const setModal = useContext(ModalContext)

	const onEdit = (e) => {
    const indexValue = index(spec, e.currentTarget.value) 

		client
			.editScreen({ assessmentid: assessmentId, index: indexValue })
			.then((response) => {
				const nextState = decodeAssessmentState(response.state)
				setState(nextState)
			})
			.catch((e) => console.error(e))
	}

	const handleConfirm = () => {
		client
			.finishAssessment({ recordid: assessmentId })
			.then((res) => {
				const decoded = decodeAssessmentState(res.state)
				setState(decoded)
        setModal()
        onEvent({ type: "CONFIRM_ANSWERS_ATTESTATION" })
			})
			.catch((e) => console.error(e))
	}

  const showModal = () => {
    return setModal(() => (
    <ConfirmModal
      setModal={setModal}
      onConfirm={handleConfirm}
    />
  ))}

  return (
    <div className="summary">
      <div className="list-container">
        <h1>
          { repurchase
            ? "Has anything changed since the last time you visited?"
            : "Please review all your answers. You can click on any answer to change it."
          }
        </h1>

        <div className="list">
          {keyValues.map(({ id, answer, questionId }) => (
            <Fragment key={id}>
              {/* Making sure we only display items included in the summaryQuestion object */}
              {summaryQuestion[id] && (
                <div key={id} className="summary-item">
                  <div className="summary-item-content">
                    <h2 className="summary-item-questions">{summaryQuestion[id]}</h2>
                    <p className="summary-item-answers">{answer}</p>
                  </div>
                  <button value={questionId || editOverride[id] || id} onClick={onEdit}>
                    <img src={icons["edit2"]} alt="" />
                    <span className="sr-only">{`edit ${summaryQuestion[id]}`}</span>
                  </button>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>

      <div className="button-container">
        <Button onClick={showModal} className="secondary">
          I Checked My Answers
        </Button>
      </div>
    </div>
  )
}

export default SummaryPage