import React from "react"
import { useState, useEffect, useContext, useCallback } from "react"

import { createComponent } from "./AssessmentComponents"
import { validate, questions } from "../spec/utils"
import { useAssessment } from "../AssessmentContext"
import ModalContext from "../modal"

import { BpNumbersRecentModal, BpModal } from "./ConfirmModals"
import BaseScreen from "./Screens/BaseScreen"
import TitleBanner from "./TitleBanner"

import "../css/BaseScreen.css"

const confirmModals = {
  bpnumbers: BpModal,
  knowBpNumbers: BpNumbersRecentModal,
}

export function Screen({
  id,
  answers,
  buttonText,
  children,
  confirm,
  getValue,
  heroImage,
  onBack,
  onEvent,
  onSubmit,
  screenSpec,
  showBackButton,
  showButtonContainer,
  showContinueButton,
  state,
  style,
  subtitle,
  title,
}) {
  useEffect(() => {
    onEvent({ componentId: id, type: "SCREEN_DISPLAYED" })
  }, [id])

  const setModal = useContext(ModalContext)

  const confirmBeforeSubmit = (a) => {
    const errs = validate(screenSpec, answers)
    if (Object.keys(errs).length > 0) {
      return onSubmit(a) // prevents modal from showing if validation errors
    }

    for (let i = 0; i < confirm.length; i++) {
      const c = confirm[i]
      const fn = new Function(`return state => ${c.cond}`)()
      if (fn(answers)) {
        const key = c.modal || id

        const handleConfirm = (v) => {
          // console.log("confirming", v)
          console.log("a", a)
          setModal()
          onSubmit({ ...a, ...v })
          onEvent({ type: "CONFIRM", componentId: `${key}Confirm` })
        }

        const closeModal = () => setModal()

        const Component = confirmModals[key]
        const createModal = () => (
          <div className="flex flex-col rounded">
            <Component
              answers={answers}
              onConfirm={handleConfirm}
              onClose={closeModal}
              onEvent={onEvent}
              getValue={getValue}
            />
          </div>
        )

        setModal(createModal)
        return
      }
    }

    onSubmit(a)
  }

  const handleSubmit = confirm ? confirmBeforeSubmit : onSubmit
  const remaining = questions(screenSpec).filter((q) => answers[q.id] === undefined)

  const isPrivacyNoticeScreen = id === "PrivacyNoticeScreen"

  return (
    <>
      { isPrivacyNoticeScreen && <div className="absolute top-0 left-0 right-0"><TitleBanner text="Privacy Notice" /></div>}

      <BaseScreen
        buttonText={buttonText || "Next"}
        disableContinue={remaining.length}
        heroImage={heroImage}
        onBack={onBack}
        onSubmit={() => handleSubmit()}
        style={style || {}}
        subtitle={subtitle}
        showBackButton={showBackButton}
        showButton={showContinueButton === true}
        showButtonContainer={showButtonContainer}
        state={state}
        title={title}
      >
        {children}
      </BaseScreen>
    </>
  )
}

export function Assessment({ showBackButton }) {
  const [auditTrail, setAuditTrail] = useState([]) // trail of events
  const { spec, state, onBack, onEvent, onSubmit } = useAssessment()

  // todo: possibly make exceptions to conditional screens so it doesnt blow everything up in the summary screen
  const initScreenState = useCallback(() => {
    let storedState = sessionStorage.getItem("screenState")

    if (storedState && storedState !== "undefined") {
      const parsedState = JSON.parse(storedState)
      if (Object.keys(parsedState.answers).length > 0) {
        return parsedState
      }
    }

    const screenState = { answers: {} }
    questions(spec.children[state.index]).forEach((q) => {
      if (state.answers[q.id] !== undefined) {
        screenState.answers[q.id] = state.answers[q.id]
      }
    })

    return screenState
  }, [state.index, state.answers, spec.children])

  const setScreenStateWithStorage = (newState) => {
    sessionStorage.setItem("screenState", JSON.stringify(newState))
    setScreenState(newState)
  }

  const [screenState, setScreenState] = useState(initScreenState())
  const [shouldShowErrs, setShouldShowErrs] = useState(false)

  const screenErrs = validate(spec.children[state.index], screenState.answers)

  useEffect(() => {
    setScreenStateWithStorage(initScreenState())
  }, [initScreenState])

  const handleEvent = (e) => {
    if (e.type === "UPDATED_ANSWER") {
      const updatedState = { answers: { ...screenState.answers, [e.componentId]: e.newValue } }
      setScreenStateWithStorage(updatedState)
    }
    setAuditTrail((t) => [...t, e])
    onEvent(e)
  }

  const handleSubmit = (a) => {
    // yes/no buttons submit their answers at the same time the screen is submitted
    const all = { answers: { ...screenState.answers, ...a } }
    const errs = Object.keys(screenErrs).length > 0
    if (errs) {
      if (!shouldShowErrs) {
        setShouldShowErrs(true)
      }
      return
    }

    if (shouldShowErrs) setShouldShowErrs(false)

    onSubmit(all)
  }

  const hideErrs = () => setShouldShowErrs(false)

  const screenSpec = spec.children[state.index]
  const getValue = (qid) => screenState.answers[qid]
  const props = {
    isLoading: false,
    onSubmit: handleSubmit,
    onEvent: handleEvent,
    getValue,
    answers: { ...state.answers, ...screenState.answers },
    shouldShowErrs,
    screenErrs,
    hideErrs,
    screenSpec,
    onBack,
    showBackButton,
    state,
    spec,
    progress: (state.index / spec.children.length) * 100,
  }

  return createComponent(screenSpec, props)
}
