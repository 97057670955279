function AssessmentReferenceNumber({ state, assessmentNumber }) {
  const formatAssessmentNumber = (num) => {
    if (num == null || num < 0) return "";
    return num > 99 
			? num.toString() 
			: num.toString().padStart(3, "0");
  };

  return (
    <div className={`assessment-reference-number ${state.outcome === "ok" ? "outcome-ok" : ""}`}>
      Assessment ID - {formatAssessmentNumber(assessmentNumber)}
    </div>
  );
};

export default AssessmentReferenceNumber;