import icons from "../icn/index"

// components
export function Card({ id, header, children, className, onEdit }) {
  const handleOnEdit = () => {
    console.log('neat, we going to edit', id)
    onEdit(id)
  }

  return (
    <div className={`card h-full bg-[#FEFEFE] shadow-md rounded-[10px] p-4 ${ className || '' }`}>
      <div className="flex justify-between items-center">
        <h2 className="mb-2 font-bold text-[24px] leading-[36px] text-primary-500 flex-1">
          {header}
        </h2>

        <button className="edit-button" onClick={handleOnEdit} aria-label="Edit">
          <img className="h-8 w-8" src={icons["edit2"]} alt="" />
        </button>
      </div>

      {children}
    </div>
  )
}

export function CardSpan({ children, className }) {
  return (
    <p className={`card-span text-base text-primary-500 m-0 ${className || ""}`}>
      {children}
    </p>
  )
}

export function CardText({ children, className }) {
  return (
    <p className={`card-text text-base text-primary-500 font-semibold m-0 ${className || ""}`}>
      {children}
    </p>
  )
}

export function ConfirmationCard({ header, children, className }) {
  return (
    <div className={`confirmation-card h-full bg-[#FEFEFE] shadow-md rounded-[10px] p-4 ${className || ""}`}>
      <h2 className="mb-2 font-bold text-[24px] leading-[36px] text-primary-500">
        {header}
      </h2>

      {children}
    </div>
  )
}

export function Dollars({ cents }) {
  return `$${(cents / 100).toFixed(2)}`;
}

// utils
export function clamp(min, v, max) {
  if (v < min) {
    return min
  }
  if (v > max) {
    return max
  }
  return v
}

export function calculateTotalCost(subtotal, shipping, tax) {
  // Convert all inputs to numbers to ensure correct addition
  const total = Number(subtotal) + Number(shipping) + Number(tax);
  return total;
}