import { useContext, useEffect } from 'react'
import ModalContext from '../../modal'

import './OfflineNotification.css'

export function OfflineModalContents() {
  return <div className='offline-container'>
    <div className='modal'>
      <h2>Lost Connection</h2>
      <p>We've detected lost network connectivity. Please check your internet connection. This overlay will close once connectivity is restored.</p>
    </div>
  </div>
}

function OfflineNotification({ isOnline }) {
  const setModal = useContext(ModalContext);

  const handleSetModal = () => {
    if (!isOnline) {
      setModal(() => <OfflineModalContents />, { className: 'offline-modal' });
    } else {
      setModal(null);
    }
  };

  useEffect(() => {
    handleSetModal();
  }, [isOnline]);

  return null;
}

export default OfflineNotification;
