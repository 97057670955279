// RouteChangeHandler.js
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function RouteChangeHandler() {
  const location = useLocation()

  useEffect(() => {
    // Logic to clear session storage when not on a checkout-related path
    const currentPath = location.pathname
    if (!currentPath.startsWith('/checkout')) {
         // Remove all checkout-related session storage
         sessionStorage.removeItem('checkoutStep')
         sessionStorage.removeItem('subtotal')
         sessionStorage.removeItem('amount')
         sessionStorage.removeItem('selectedProduct')
         sessionStorage.removeItem('shippingAddress')
         sessionStorage.removeItem('shippingMethod')
         sessionStorage.removeItem('paymentMethod')
         sessionStorage.removeItem('billingAddress')
         sessionStorage.removeItem('paymentDetails')
         sessionStorage.removeItem('paymentAddressSame')
    }
  }, [location])

  return null // This component does not render anything
}
