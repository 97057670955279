import { useContext, useState } from "react"
import ModalContext from "../../modal"
import { useAudit } from "../../audit"

import AdopbuList from "./AdopbuList"
import { AssessmentConfirmingModal } from "../../components/AssessmentConfirmingModal"
import Button from "../../components/AssessmentComponents/Button"
import KickoutScreen from "../../components/Screens/KickoutScreen"

import adbu from "../../img/adbu.png"

export function ADOPBUConfirmModal({ onConfirm, onGoBack }) {
  useAudit({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED" })

  return (
    <AssessmentConfirmingModal
      handleClose={onGoBack}
      handleContinue={onConfirm}
      text="I confirm I have talked to a doctor or pharmacist, and they said it is appropriate for me to use."
    />
  )
}

function AdopbuScreen({ answers, onConfirm, setShowDFL }) {
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({ type: "OUTCOME_ADOPBU_DISPLAYED" })
  const [showKickout, setShowKickout] = useState(false)

  if (showKickout) {
    return <KickoutScreen id="kickout-id" onEvent={onEvent} fromAdopbuScreen={true} showStopSignImage={false}>
      <div className="outcome-content">
        <img className="h-[120px] w-[120px] md:h-[200px] md:w-[200px] mx-auto" src={adbu} alt="" />

        <h1>Based on your answers, you must speak with a doctor or pharmacist before purchasing Avanafil OTC.</h1>

        <p>If a doctor or pharmacist says it is appropriate for you to use Avanafil OTC, please come back to purchase.</p>
      </div>
    </KickoutScreen>
  }

  const handleYes = async () => {
    setModal(() => (
      <ADOPBUConfirmModal
        onConfirm={handleOnConfirm}
        onGoBack={handleCloseModal}
      />
    ))
  }

  const handleNo = async () => {
    await onConfirm("no")
    setShowKickout(true)
  }

  const handleCloseModal = () => {
    setModal()
  }

  const handleOnConfirm = async () => {
    await onConfirm("yes")
    setShowDFL(true)
    setModal()
  }

  return (
    <div className="outcome-content">
      <div>
        <p className="content">
          Based on your answers, you should talk to a doctor or pharmacist about whether Avanafil OTC is appropriate for you to use because:
        </p>
        <AdopbuList answers={answers} />
      </div>
      <p className="content">Has a doctor or a pharmacist said it is appropriate for you to use?</p>
      <div className="assessment-buttons-container">
        <Button
          className="primary outline rect"
          onClick={handleYes}
        >
          Yes
        </Button>
        <Button
          className="primary outline rect"
          onClick={handleNo}
        >
          No
        </Button>
      </div>
    </div>
  )
}

export default AdopbuScreen