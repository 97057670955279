import { useState } from 'react'
import { Auth } from '../components/Auth'
import NavBar from '../components/NavBar'
import Modal from 'react-modal';
import images from "../img"

import "./QRCode.css"

export function LoginModal({isOpen, setShowAuth, onContinue}) {
  const handleClose = () => {
    setShowAuth(false)
  }

  return (
    <Modal className="qr-login-modal" overlayClassName="overlay" isOpen={isOpen} onRequestClose={handleClose}>
      <h2>Log In</h2>

      <Auth onContinue={onContinue} showGuestLogin={false}/>

      <button className="close-button" onClick={handleClose}>
        Cancel
      </button>
    </Modal>
  )
}

function QRCodePage() {
  const [showAuth, setShowAuth] = useState(false)
  const [authed, setAuthed] = useState(sessionStorage.getItem("authed"))

  const handleClick = e => {
      e.preventDefault()
      setShowAuth(true)
  }

  const handleAuthSuccess = () => {
    setShowAuth(false)
    sessionStorage.setItem("authed", true)
    setAuthed(true)
  }

  return <>
    <NavBar />

    <main className="qr-page">
      <div className="content">
        <h1 className="sr-only">Buy Stendra Today</h1>

        <h2>Show QR code to cashier</h2>

        <div className="qr-container">
          <img src={images["sample-qr"]} alt="qr code" />
        </div>

        <h1 aria-hidden="true">Buy Stendra Today</h1>

        <p>
          When you are ready to pay, show this QR code to the cashier to
          pick up your packages and complete your order.
        </p>

        { !authed && <>
          <h2>Not ready to check out?</h2>

          <button className="create-account-button" onClick={handleClick}>
            Create an account to save QR code
          </button>
        </> }
      </div>
    </main>

    <LoginModal
      isOpen={showAuth}
      onContinue={handleAuthSuccess}
      setShowAuth={setShowAuth}
    />
  </>
}

export default QRCodePage