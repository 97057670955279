import { useSearchParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { assessmentStarted } from "../reducers"
import { googleAuth, appleAuth } from "./Auth"
import { Auth } from "./Auth"
import { useAssessment } from "../AssessmentContext"

import "./AuthWrapper.css"


function AuthWrapper({ onSubmitassessmentState, displayTitle=true, inProgress, outcomeOnSubmit }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state, assessmentId, onSubmit } = useAssessment()

  const [searchParams] = useSearchParams()
  const assessmentState = searchParams.get("assessmentState") || onSubmitassessmentState

  const onApple = async (authType) => {
    await appleAuth(authType, true)
    onSubmit()
  }

  const onGoogle = async (authType) => {
    await googleAuth(authType, true)
    onSubmit()
  }

  const onGuest = async () => {
    sessionStorage.removeItem("continuewithEmail")

    // if inProgress flag or there is an existing assessment...
    if(inProgress === true || !!assessmentId) {
      // if we already have an assessment id and are in the outcome flow
      if (assessmentState === "outcome") {
        sessionStorage.setItem("OutcomeFlow_showSignInScreen", "false")
        // if in asswssment, call the prop passed
        if (outcomeOnSubmit) {
          outcomeOnSubmit()
          return
        }

        // if we are on create-account or sign-in page, we need to be taken to the assessment page
        navigate(`/assessment/${assessmentId}`, { replace: true })
        return
      }

      // if we have an assessment id we have to submit the answers
      await onSubmit({answers: state.answers})

      // we have an assessment id but are not in the assessment flow
      // possibly on the create-acccount or sign-in page
      // we need to be taken to the assessment page to resume
      if(!inProgress) {
        navigate(`/assessment/${assessmentId}`, { replace: true })
      }
      return
    }

    // console.error("assessmentId not found")
    dispatch(assessmentStarted())
  }

  const handleOnSelect = (name, _) => {
    if (name === "guest") {
      onGuest()
    } 
    
    if (name === "google") {
      onGoogle()
    } 
    
    if (name === "apple") {
      onApple()
    }
  }

  return (
    <div className="auth-wrapper-container">
      { displayTitle ? (
        <h1 className="auth-title">
          Don't lose your progress. <br className="md:hidden" />Create an account.
        </h1>
      ) : null }

      <div className="auth-container">
        <Auth
          assessmentState={assessmentState}
          onSelect={handleOnSelect}
        />
        <p className="text-policy">
          By using your Google or Apple ID you accept our <span className="text-link">terms of use</span> and <span className="text-link">privacy policy</span>. 
          <br className="md:hidden" /> If you continue as a guest, your answers will not be saved when you quit and you will need to restart.
        </p>
      </div>
    </div>
  )
}

export default AuthWrapper