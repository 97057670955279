import AuthWrapper from '../AuthWrapper'

export function SignUp({ onSubmit }) {
  return <AuthWrapper onSubmit={ onSubmit } assessmentState="assessment" inProgress={true}/>
}

// Assessment component for signing in during assessment
export function SignUpComponent({ onSubmit }) {
  return (
    <SignUp
      isOpen={true}
      onSubmit={onSubmit}
    />
  )
}