import React, { createElement } from "react"

// Removing googleAuth and appleAuth imports make the tests fail.. :shrug:
import { googleAuth, appleAuth } from "../components/Auth"

import { BannerWithOptions } from "./AssessmentComponents/Banner"
import Button from "./AssessmentComponents/Button"
import Checkbox from "./AssessmentComponents/Checkbox"
import CheckboxList from "./AssessmentComponents/CheckboxList"
import Image from "./AssessmentComponents/Image"
import IsGuest from "./AssessmentComponents/IsGuest"
import { TitleInfo } from "./MoreInfo"
import NitratesMoreInfo from "./AssessmentComponents/NitratesMoreInfo"
import NumericInput from "./AssessmentComponents/NumericInput"
import OptionSelect from "./AssessmentComponents/OptionSelect"
import Paragraph from "./AssessmentComponents/Paragraph"
import PrivacyNotice from "./AssessmentComponents/PrivacyNotice"
import RadioGroup from "./AssessmentComponents/RadioGroup"
import { SignUpComponent } from "./AssessmentComponents/SignUp"
import TextInput from "./AssessmentComponents/TextInput"
import YesNoButtons from "./AssessmentComponents/YesNoButtons"
import { Screen } from "./Assessment"
import KickoutScreen from "./Screens/KickoutScreen"
import Title from './AssessmentComponents/Title'

import "../css/Typography.css"

export function range(a, b) {
  const ret = []
  for (var i = a; i < b; i++) {
    ret.push(i)
  }
  return ret
}

export function ComponentList({ ordered = false, style = {}, options, title }) {
  const titleStyle = {
    marginBottom: "12px",
    ...style.title,
  }
  return ordered ? (
    <div style={style.root}>
      {title ?? <p style={titleStyle}>{title}</p>}
      <ol style={style.list || {}}>
        {options.map((opt) => (
          <li style={style.item || {}} key={opt} dangerouslySetInnerHTML={{ __html: opt }}></li>
        ))}
      </ol>
    </div>
  ) : (
    <div style={style.root}>
      {title && <p style={titleStyle}>{title}</p>}
      <ul style={style.list || {}}>
        {options.map((opt) => (
          <li style={style.item || {}} key={opt} dangerouslySetInnerHTML={{ __html: opt }} className="list-item"></li>
        ))}
      </ul>
    </div>
  )
}

export const components = {
  PrivacyNotice,
  BannerWithOptions,
  Button,
  Checkbox,
  CheckboxList,
  Image,
  List: ComponentList,
  NumericInput,
  OptionSelect,
  Paragraph,
  RadioGroup,
  TextInput,
  Title,
  TitleInfo,
  YesNoButtons,
  NitratesMoreInfo,
  IsGuest,
  SignUpComponent,
  Screen,
  KickoutScreen,
}

export function registerComponent(name, def) {
  components[name] = def
}

export function registerCustomComponent(name, def) {
  const fn = (props) => {
    return createComponent(def, props)
  }

  fn.displayName = name
  components[name] = fn
}

export const ComponentContext = React.createContext({})
//getValue, onEvent, onSubmit
export function createComponent(componentspec, props = {}) {
  // create read only component

  // don't pass key through the tree, or else we get children w/ dupe keys
  //const { key, ...rest } = props
  const rest = Object.assign({}, props, { key: undefined })

  var renderedchildren = []
  if (componentspec.children) {
    renderedchildren = componentspec.children.map((c) => createComponent(c, rest))
  }

  const component = components[componentspec.type]
  if (component === undefined) {
    console.error("component missing", componentspec.type)
    return null
  }

  const { children, ...specProps } = componentspec
  const mergedProps = {
    ...specProps,
    ...props,
  }

  if (componentspec.id) {
    //FIXME:
    mergedProps.value = props.getValue && props.getValue(componentspec.id)
    mergedProps.key = componentspec.id

    const err = props.screenErrs?.[componentspec.id]
    if (err && props.shouldShowErrs) {
      mergedProps.err = err
    }
  }

  return createElement(component, mergedProps, ...renderedchildren)
}
