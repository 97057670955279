import { useEffect } from "react"

// "Fake" component to check if user is on a guest log in or native account log in.
// TODO: might need to be updated when Oauth in implemented. or just make our Oauth verification route also return the guest bool
function IsGuest({ onEvent }) {
  useEffect(() => {
    const token = JSON.parse(sessionStorage.getItem("token"))

    // Check if the user is a guest
    const isGuest = Boolean(token.guest)

    onEvent({ type: "UPDATED_ANSWER", componentId: "isGuest", newValue: isGuest })
  }, [])

  return null
}

export default IsGuest