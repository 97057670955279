import React, { useState } from "react"
import Modal from "react-modal"
import ModalBaseScreen from "./Screens/ModalBaseScreen"
import moreinfo from "../icn/more-info-2.svg"
import { useAudit } from "../audit"
import { useAssessment } from "../AssessmentContext"

import modalClose from "../icn/modal-close.svg"
import "../css/MoreInfo.css"

const modalStyle = {
  overlay: {
    backgroundColor: "var(--primary-focus)",
    zIndex: 5,
  },
  content: {
    background: "var(--tertiary)",
    height: "100%",
    maxHeight: "100vh",
    overflowY: "auto",
    position: "absolute",
  },
}

export function Audited({ children }) {
  const { spec, state } = useAssessment()
  const e = { type: "MOREINFO_DISPLAYED" }
  const screenId = spec?.children[state.index].id
  if (screenId) { e.screenId = screenId }
  
  useAudit(e)
  return <>{children}</>
}

export function MoreInfoModal({ isOpen, onRequestClose, children }) {
  const wrapperClass = isOpen ? "active" : "deactive"

  // Animation for the modal - sliding in / out
  const handleAnimationModal = () => {
    console.log("handleAnimationModal clickity click")
    const modal = document.getElementById("modal")
    modal.style.animationName = "slideout"
    setTimeout(onRequestClose, 490)
  } 

  return (
    <Modal
      id="modal"
      data-testId="moreInfoModal"
      isOpen={isOpen}
      style={modalStyle}
      onRequestClose={handleAnimationModal}
      className={`wrapper-animation-${wrapperClass} more-info-container`}
    >
      <div className="title-container">
        <h2>More Information</h2>
        
        <button className="rounded-full" onClick={handleAnimationModal} aria-label="Close modal">
          <img src={modalClose} alt="" />
        </button>
      </div>
      <div>
        <ModalBaseScreen onSubmit={handleAnimationModal}>
          <Audited>{children}</Audited>
        </ModalBaseScreen>
      </div>
    </Modal>
  )
}

export function MoreInfoIconPlus({ onClick }) {
  const handleOnClick = (e) => {
    e.preventDefault()
    onClick(e)
  }

  return (
    <button className="flex items-end text-lg rounded md:leading-[48px]"
      onClick={handleOnClick}
    >
      <div className="inline-flex justify-center items-center">
        <img className="h-6 w-6 md:h-8 md:w-8" src={moreinfo} alt="" />
      </div>
      <span className="md:text-2xl text-[#0035F2] font-semibold underline">
        More Info
      </span>
    </button>
  )
}

export function TitleInfo({ left, right, children, styleLink }) {
  const [showingInfo, setShowingInfo] = useState(false)

  // Define a condition for when to display the tooltip inline
  // TODO could add a prop in the v.json for this
  const displayTooltipInline = left && (
    left.includes("Have you ever been told by a doctor that you have heart failure?") || 
    left.includes("Do you have any of the following symptoms? Click all that apply.") ||
    left.includes("Have you ever had any of the following heart issues? Click all that apply.") ||
    left.includes("Have you ever been prescribed a nitrate medicine (including isosorbide, nitroglycerin) for chest pain or heart failure?")
  )

  let title = right ? `${left} ${right}` : left

  const handleOnClick = (e) => {
    e.preventDefault()
    setShowingInfo(true)
  }

  const handleClose = () => { setShowingInfo(false) }

  return (
    <div>
      { displayTooltipInline 
        ? <>
          <h1 className="assessment-title primary">
            {/* ABSOLUTELY FILTHY hack to make sure "Click all that apply" is on a new line to match manuscript */}
            { title && <>
              { title.includes('Click all that apply.')
                ? title.split('Click all that apply.').map((part, index) => (
                    <React.Fragment key={index}>
                      {part}
                      {index > 0 && <><br/><span>Click all that apply. </span></>}
                    </React.Fragment>
                  ))
                : title + " "
              }
              </>}
            <div className="inline-flex justify-center items-center translate-y-1">
              <MoreInfoIconPlus onClick={handleOnClick} />
            </div>
          </h1>
          {/* Ensure MoreInfoModal is rendered and receives showingInfo as isOpen prop */}
          <MoreInfoModal isOpen={showingInfo} onRequestClose={handleClose}>
            {children}
          </MoreInfoModal>
          </>
       : <>
          <div>
            { title && (
              <h1 className="assessment-title primary">
                <span>{title}</span>
              </h1>
            )}
            <div style={styleLink}>
              <MoreInfoIconPlus onClick={handleOnClick} />
              <MoreInfoModal isOpen={showingInfo} onRequestClose={handleClose}>
                { children }
              </MoreInfoModal>
            </div>
          </div>
      </>}
    </div>
  )
}
