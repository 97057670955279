import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, useNavigate } from 'react-router-dom';

import WarningModal from './components/WarningModal';
import ModalContext from './modal'

export function useBlocker( blocker, when = true ) {
  const { navigator } = useContext( NavigationContext );

  console.log('useBlocker called');
  useEffect( () => {
    if ( !when ) return;


    const unblock = navigator.block( ( tx ) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker( autoUnblockingTx );
    } );

    return () => {
      if(unblock) {
        unblock();
      }
    };
  }, [ navigator, blocker, when ] );
}

export let skipNext = false

export function skipPrompt() {
  skipNext = true;
}

export function getSkipNext() {
  return skipNext;
}

export function setSkipNext(value) {
  skipNext = value;
}

export function createCheckoutModal(handleLeave, onClose) {
  return (
    <WarningModal
      handleLeave={handleLeave}
      handleContinue={onClose}
      title="Exit Checkout"
      text="You are about to leave checkout. If you leave, your information may not be saved."
      btnLeaveText="Yes, Leave"
      btnContinueText="No, Continue"
    />
  );
}

export function createAssessmentModal(onConfirm, onClose) {
  return (
    <WarningModal
      handleLeave={onConfirm}
      handleContinue={onClose}
      title="Exit Health Survey"
      text="You are about to leave the health survey. If you leave, your information may not be saved. Are you sure you want to exit?"
      btnLeaveText="Leave"
      btnContinueText="Close"
    />
  );
}

export function handleNavigationConfirmation(tx, skipNext, blockBrowserBackButton, setModal, onEvent, navigate) {
  const onClose = () => setModal();

  const onConfirm = () => {
    setModal();
    onEvent({ type: "EXIT_CONFIRM" });
    skipPrompt();
    navigate('/', { replace: true });
  };

  const handleLeave = () => {
    setModal();
    onEvent({ type: "PURCHASE_SCREEN_DISPLAYED" });
    tx.retry();
  };

  // Check specific navigation paths
  if (tx.location.pathname === '/500' || tx.location.pathname === '/404') {
    tx.retry();
    return { handleLeave, onConfirm, onClose }; // Return default values
  }

  if (skipNext && !blockBrowserBackButton) {
    setSkipNext(false);
    tx.retry();
    return { handleLeave, onConfirm, onClose }; // Return default values
  }

  if (tx.location.state?.shouldSkipPrompt && !blockBrowserBackButton) {
    tx.retry();
    return { handleLeave, onConfirm, onClose }; // Return default values
  }

  return { handleLeave, onConfirm, onClose }; // Ensure we return these functions
}

export function usePrompt(msg, when, onEvent, location, blockBrowserBackButton=false) {
  const navigate = useNavigate();
  const setModal = useContext(ModalContext);

  console.log('usePrompt invoked', { when, location }); // Add log

  const blocker = useCallback(
    (tx) => {
      console.log('blocker called with tx:', tx); // Add log
      const { handleLeave, onConfirm, onClose } = handleNavigationConfirmation(
        tx, 
        skipNext, 
        blockBrowserBackButton, 
        setModal, 
        onEvent, 
        navigate
      );

      if (blockBrowserBackButton) {
        if (tx.location.state?.canNavigate) {
          onConfirm();
        }
      } else {
        if (location === 'assessment') {
          console.log('Assessment Modal Triggered'); // Add log
          setModal(() => createAssessmentModal(onConfirm, onClose), { className: "exit-modal" });
        }
        if (location === 'checkout') {
          console.log('Checkout Modal Triggered'); // Add log
          setModal(() => createCheckoutModal(handleLeave, onClose), { className: "exit-modal" });
        }
      }
    },
    [msg, location, blockBrowserBackButton, onEvent, navigate]
  );

  useBlocker(blocker, when);
}