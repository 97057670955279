import Button from "../AssessmentComponents/Button";
import Title from "../AssessmentComponents/Title";
import { createComponent } from "../AssessmentComponents";

import "../../css/BaseScreen.css"

function BaseScreen({
  title,
  children,
  showButton = true,
  style = {},
  onSubmit,
  backButtonText = "Back",
  buttonText = "Next",
  disableContinue,
  onBack,
  showBackButton,
  showButtonContainer = true,
}) {
  const innerStyle = {
    ...style.inner,
  };

  return (
    <>
      {title &&
        (typeof title == "string" ? (
          <Title text={title} />
        ) : (
          createComponent(title)
        ))}

      <div className="inner" style={innerStyle}>
        {children}
      </div>

      {showButtonContainer && (
        <div className="button-container">
          {showBackButton && (
            <Button
              onClick={onBack}
              className="primary outline thin"
              style={{ flex: "0 1 400px" }}
            >
              {backButtonText}
            </Button>
          )}

          {showButton && (
            <Button
              onClick={onSubmit}
              className="secondary w-full"
              disabled={disableContinue}
            >
              {buttonText}
            </Button>
          )}
        </div>
      )}
    </>
  );
}

export default BaseScreen;
