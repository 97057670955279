import React from "react"
import { Link } from "react-router-dom"
import LayoutScreen from "../../components/Layout"
import errorCodeImg from "../../img/405.svg"
import "./ErrorPages.css"

function MethodNotAllowed() {
  return (
    <LayoutScreen>
      <div className="error-page-content method-not-allowed">
        <img src={errorCodeImg} alt="method not allowed illustration" />

        <h1 role="alert">
          The action you attempted is not permitted on this resource.
        </h1>

        <Link to="/" className="btn secondary text-center" replace={true}>
          Back to Homepage
        </Link>
      </div>
    </LayoutScreen>
  )
}

export default MethodNotAllowed