import './Radio.css'

function Radio({ name, value, title, text, disabled, checked, ariaLabel = text, onChange }) {
  return <label 
    className="assessment-radio"
    htmlFor={`assessment-radio-${value}`}
    data-disabled={disabled}>
    <div className="relative flex">
      <input
        type="radio"
        id={`assessment-radio-${value}`}
        className="assessment-radio__input"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        aria-checked={checked}
        aria-label={ariaLabel}
        onChange={(e) => onChange(e.target.value)}
      />
      <svg
        className="assessment-radio__custom-radio"
        width="30"
        height="30"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
      >
        <path d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32V32C7.16344 32 0 24.8366 0 16V16Z" />
        <path d="M11 17L14 20L20.5 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
    <div className="assessment-radio__label">
      {/* RZ: i dislike this bc it assumes that h2 is always the next header level */}
      { title ? <h2>{title}</h2> : null }
      { text ? <p>{text}</p> : null }
    </div>
</label>
}

export default Radio;