// Libraries
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Components
import LayoutScreen from '../components/Layout';
import { 
  Card, 
  ConfirmationCard, 
  CardText, 
  CardSpan,
  clamp, 
  Dollars,
} from '../components/CheckoutComponents'
import SelectProduct from './checkout/SelectProduct';
import ShippingAddress from './checkout/ShippingAddress';
import ShippingMethod from './checkout/ShippingMethod';
import { PaymentMethod } from './checkout/PaymentInformation';
import { AdjustedProgress } from '../components/CheckoutProgress.js';
import images from '../img'
import icons from "../icn/index"

// Utilities
import { checkoutClient } from '../client';
import history from '../history';
import { usePrompt } from "../prompt"
import { useCheckout } from '../CheckoutContext.js';

// Styles
import "./Checkout.css";
import "../css/Typography.css";

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

export function ViewOrder({productName, subtotal, shippingMethodTitle, shippingCost, tax, amount, firstname, lastname, line1, line2,
  city, state, postal_code, brand, last4, goto}) {
  
  const cards = {
    visa: images['card-visa'],
    amex: images['card-amex'],
    mastercard: images['card-mastercard'],
    discover: images['card-discover'],
  }

  const cardImage = cards[brand] ? <img src={cards[brand]} alt={brand} style={{ width: "52px", height: "auto" }} /> : null;

  return (
    <div className="flex gap-2 flex-col md:flex-row">
      <div className="flex flex-col gap-2 flex-auto min-w-[250px]">
        <Card id="address" header="Shipping Address" onEdit={goto}>
          <CardText>{firstname} {lastname}</CardText>
          <CardText>{line1}, {line2}</CardText>
          <CardText>{city}, {state} {postal_code}</CardText>
        </Card>

        <Card id="shipping_method" header="Shipping Method" onEdit={goto}>
          <CardText>{shippingMethodTitle}</CardText>
        </Card>

        <Card id="payment_method" header="Payment Method" onEdit={goto}>
          <div className='flex items-center gap-2'>
            {cardImage}
            <CardText>Ending in {last4}</CardText>
          </div>
        </Card>
      </div>

      <div className="flex-auto">
        <Card id="product" header="Order Summary" className="h-full" onEdit={goto}>
          <OrderSummary productName={productName} subtotal={subtotal} shippingCost={shippingCost} tax={tax} amount={amount} postalCode={postal_code} />
        </Card>
      </div>
    </div>
  )
}

export function ViewConfirmation({ productName, subtotal, shippingMethodTitle, shippingCost, tax, amount, firstname, lastname, line1, line2,
  city, state, postal_code, brand, last4 }) {
  
  const cards = {
    visa: images['card-visa'],
    amex: images['card-amex'],
    mastercard: images['card-mastercard'],
    discover: images['card-discover'],
  }

  const cardImage = cards[brand] ? <img src={cards[brand]} alt={brand} className='h-auto w-[52px]' /> : null;

  return (
    <div className='flex gap-6 md:gap-4 flex-col md:flex-row'>
      <div className="flex flex-col gap-6 md:gap-4 flex-auto min-w-[250px]">
        <ConfirmationCard header="Shipping Address">
          <CardText>{firstname} {lastname}</CardText>
          <CardText>{line1}, {line2}</CardText>
          <CardText>{city}, {state} {postal_code}</CardText>
        </ConfirmationCard>
        <ConfirmationCard header="Shipping Method">
          <CardText>{shippingMethodTitle}</CardText>
        </ConfirmationCard>
        <ConfirmationCard header="Payment Method">
          <div className="flex items-center gap-2">
            {cardImage}
            <CardText>Ending in {last4}</CardText>
          </div>
        </ConfirmationCard>
      </div>
      <div className='flex-auto'>
        <ConfirmationCard header="Order Summary">
          <OrderSummary 
            productName={productName} 
            subtotal={subtotal} 
            shippingCost={shippingCost} 
            tax={tax} 
            amount={amount} 
            postalCode={postal_code}
          />
        </ConfirmationCard>
      </div>
    </div>
  )
}

export function OrderSummary({ productName, subtotal, shippingCost, tax, amount, postalCode }) {
  return (
    <div>
      <ul className="order-summary-list m-0 p-0 list-none">
        <li className="order-summary-item list-none font-semibold">
          <div className="row-item flex justify-between items-center">
            <CardSpan>{productName}</CardSpan>
            <CardSpan className="font-bold"><Dollars cents={subtotal} /></CardSpan>
          </div>
        </li>
        <li className="order-summary-item list-none font-semibold">
          <div className="row-item flex justify-between items-center">
            <CardSpan>Subtotal (1 item)</CardSpan>
            <CardSpan className="font-bold"><Dollars cents={subtotal} /></CardSpan>
          </div>
        </li>
        <li className="order-summary-item list-none font-semibold">
          <div className="row-item flex justify-between items-center">
            <CardSpan>Shipping</CardSpan>
            <CardSpan className="font-bold"><Dollars cents={shippingCost} /></CardSpan>
          </div>
        </li>
        <li className="order-summary-item list-none font-semibold">
          <div className="row-item flex justify-between items-center">
            <div className="tax-wrapper flex flex-col justify-center h-full">
              <CardSpan>Estimated Tax</CardSpan>
              <CardSpan>(Tax for {postalCode})</CardSpan>
            </div>
            <CardSpan className="font-bold"><Dollars cents={tax} /></CardSpan>
          </div>
        </li>
        <li>
          <hr className='mb-2 border-primary-500' />
          <div className="flex justify-between items-center text-2xl font-bold">
            <span>Total</span>
            <span><Dollars cents={amount} /></span>
          </div>
        </li>
      </ul>
    </div>
  )
}

export function PreviewOrder({ goto, onSubmit }) {
  const { 
    product, 
    address, 
    shippingMethod, 
    paymentMethodDetails, 
    cost 
  } = useCheckout()
  
  return (
    <div className='space-y-6 md:space-y-8'>
      <div>
        <h1 className="screen-title">
          Preview Order
        </h1>
        <p className='screen-step'>(Step: 5/6)</p>
      </div>

      <ViewOrder 
        productName={product.description}
        subtotal={product.price}
        firstname={address.firstname}
        lastname={address.lastname}
        line1={address.line1}
        line2={address.line2}
        city={address.city}
        state={address.state}
        postal_code={address.zip}
        shippingMethodTitle={shippingMethod.description}
        shippingCost={shippingMethod.price}
        brand={paymentMethodDetails.brand}
        last4={paymentMethodDetails.last4}
        shipping={cost.shipping}
        tax={cost.tax}
        amount={cost.amount}
        goto={goto}
      />

      <button className='btn secondary' onClick={onSubmit}>Purchase</button>
    </div>
  )
}

export function OrderConfirmation() {
  const { 
    product, 
    address, 
    shippingMethod, 
    paymentMethodDetails, 
    cost
  } = useCheckout()
  
  return (
    <div className='space-y-6 md:space-y-8'>
      <div>
        <h1 className="screen-title">
          Order Confirmation
        </h1>
        <p className='screen-step mb-4'>(Step: 6/6)</p>
        <p className="font-semibold text-base md:text-[24px]">
          You have successfully placed your order. Your order number is <span className="font-bold text-green-600">abc-12345</span>.
        </p>
      </div>

      <ViewConfirmation 
        productName={product.description}
        firstname={address.firstname}
        lastname={address.lastname}
        line1={address.line1}
        line2={address.line2}
        city={address.city}
        state={address.state}
        postal_code={address.zip}
        shippingMethodTitle={shippingMethod.description}
        shippingCost={shippingMethod.price}
        brand={paymentMethodDetails.brand}
        last4={paymentMethodDetails.last4}
        subtotal={cost.subtotal}
        shipping={cost.shipping}
        tax={cost.tax}
        amount={cost.amount}
      />

      <div className="text-base md:text-[24px] md:leading-[36px] tracking-[-0.176px] md:tracking-[-0.264px] text-blue-600 font-semibold flex items-center gap-2">
        View Receipt
        <img className='h-6 w-6 md:h-7 md:w-7' src={icons["link"]} alt="link opens in a new tab" />
      </div>
    </div>
  )
}

export default function Checkout() {
  const [customerId, setCustomerId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    checkoutClient.createSetupIntent({})
      .then(resp => {
        setCustomerId(resp.customerId)
        setClientSecret(resp.clientSecret)
      })
      .catch(err => {
        console.error('Error fetching client secret:', err)
        setError('Error fetching client secret')
      })
  }, [])

  const graphikNormal = {
    family: "Graphik",
    src: 'url("./fonts/Graphik-Regular.otf") format("opentype")',
    weight: "normal",
    style: "normal",
  }

  const options = {
    clientSecret,
    appearance: {
      variables: {
        colorBackground: "#FFFFFF",
        colorPrimary: "#001e46",
        colorText: "#272727",
        colorDanger: "#ED0000",
        spacingUnit: "7px",
      },
      rules: {
        ".Input::placeholder": {
          color: "transparent",
        },
      },
    },
    fonts: [graphikNormal],
  }

  return (!!clientSecret && !!customerId) 
    ? <Elements options={options} stripe={stripePromise}>
        <CheckoutInner customerId={customerId} clientSecret={clientSecret} />
      </Elements>
    : <LayoutScreen showProfile={true}>
        { error 
          ? <h1 className="p-8">{error}</h1> 
          : <h1 className="p-8">Loading...</h1>
        }
      </LayoutScreen>

}

export const PolicyDisclaimer = ({ text }) => {
  return (
    <p className="payment-policy-text">
      <small>
        *By clicking the <strong>{text}</strong> button, you agree to our{' '}
        <span className="policy-link">Terms</span> and{' '}
        <span className="policy-link">Privacy</span> policies.
      </small>
    </p>
  )
}

export const CheckoutInner = () => {
  const { 
    index, setIndex, 
    mode, setMode,
  } = useCheckout()

  const previewOrderScreenIndex = 4
  const confirmationScreenIndex = 5

  const showBackButton = index !== previewOrderScreenIndex && index !== confirmationScreenIndex
  const blockBrowserBack = index === confirmationScreenIndex
  usePrompt("one", true, () => {}, "checkout", blockBrowserBack)

  const getNextIndex = () => {
    if (mode === "NORMAL") {
      return clamp(0, index+1, screens-1)
    }
    if (mode === "EDIT") {
      if (screens[index].id === "preview") {
        return clamp(0, index+1, screens-1)
      }
      return previewOrderScreenIndex
    }
  }

  const goto = (id) => {
    const index = screens.findIndex(s => s.id === id)
    if (index >= 0) {
      setIndex(index)
    }
  }

  const edit = (id) => {
    setMode('EDIT')
    sessionStorage.setItem('isEditing', 'true')
    goto(id)
  }

  const next = () => {
    const isEditing = sessionStorage.getItem('isEditing') === 'true'
  
    // If editing, always go back to the Preview Order screen
    if (isEditing) {
      setIndex(previewOrderScreenIndex)
      sessionStorage.removeItem('isEditing') // Clear the flag
      return
    }

    const nextIndex = getNextIndex()
    setIndex(nextIndex)
  }

  const back = () => {
    const isEditing = sessionStorage.getItem('isEditing') === 'true'
  
    if (isEditing) {
      sessionStorage.removeItem('isEditing')
      setIndex(previewOrderScreenIndex) 
      return
    }
    
    // Default back behavior if not in EDIT mode
    if (index === 0) {
      history.go(-1)
      return
    }
    
    setIndex(index => Math.max(0, index - 1))
  }

  const screens = [
    { id: "product", 
      jsx: <SelectProduct key="products" onSubmit={next} />
    },
    { id: "address",
      jsx: <ShippingAddress key="address" onSubmit={next} />
    },
    { id: "shipping_method", 
      jsx: <ShippingMethod key="shipping_method" onSubmit={next} />
    },
    { id: "payment_method",
      jsx: <PaymentMethod key="payment_method" onSubmit={next} />
    },
    { id: "preview",
      jsx: <PreviewOrder key="preview" goto={edit} onSubmit={next} />,
    },
    { id: "confirmation",
      jsx: <OrderConfirmation key="confirmation" />
    }
  ]

  return (
    <div className="checkout-inner" style={{flex: "auto", display: "flex", flexDirection: "column"}}>
      <LayoutScreen context="checkout" showProfile={true} showBackButton={showBackButton} onBack={back} displayTextBtn={false}>
        <div id={screens[index].id} className="w-full max-w-[890px] container mx-auto p-6 md:py-12 md:px-8">
          <div className="mb-8 md:mb-16">
            <AdjustedProgress cur={index} />
          </div>

          <Carousel cur={index}>
            {screens.map(s => s)}
          </Carousel>

          <div className="mt-4">            
            { screens[index].id === "preview"  && <PolicyDisclaimer text="purchase" /> }
            { screens[index].id === "payment_method" && <PolicyDisclaimer text="preview order" /> }
          </div> 
        </div>
      </LayoutScreen>
    </div>
  )
}

export function Carousel({ children, cur }) {
  return <>{ children[cur] && children[cur].jsx }</>;
}