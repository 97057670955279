import images from "../../img";

function Image({ src, height, width }) {
  const imageSrc = images[src];

  if (!imageSrc) {
    return null; // or return a placeholder image
  }

  return <img src={imageSrc} alt={src} height={height || ""} width={width || ""} style={{ marginInline: "auto", marginBlock: '2rem'}} />;
}

export default Image;