import React, { useEffect, useState } from "react"
// import { useSelector } from "react-redux"

// this, for some reason, needs to be here, even though it's not used
// removing this is causing all of the tests to fail. classic petros...
import { googleAuth, appleAuth } from "./Auth"
import { authClient, client } from "../client"
import { assessmentStarted } from "../reducers"
import { useDispatch } from "react-redux"
import { useSearchParams, useNavigate, Link } from "react-router-dom"
import AuthWrapper from "./AuthWrapper"
import { useAssessment } from "../AssessmentContext"
import icons from "../icn/index"

import './SignIn.css'

const verifyEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export function SignIn() {
  // const authed = useSelector((state) => state.auth.authed)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const { assessmentId, state, onSubmit } = useAssessment()
  const [searchParams] = useSearchParams()
  const assessmentState = state.state || searchParams.get("assessmentState")

  // State object to manage the form fields and their validation
  const [formValid, setFormValid] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [error, setError] = useState({
    email: false,
    submission: "",   // needs to be a string to display the error message from the server
  })

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "email") {
      setError({ ...error, email: false })
      setEmail(value)
      
      const emailValid = verifyEmailRegex.test(value)
      if (!emailValid) {
        setError({ ...error, email: true })
      }
    }
    
    if (name === "password") {
      setPassword(value)
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // reset the errors
    setFormValid(true)
    setError({ ...error, submission: false })

    try {
      const signInResult = await authClient.nativeSignIn(email, password)
      
      if (signInResult.status !== "ok") return

      if (!assessmentId) {
        dispatch(assessmentStarted())
        return
      }

      if (!!assessmentId && assessmentState === "assessment") {
        onSubmit({answers: {
          ...state.answers,
          isGuest: false,
        }})
      } 
      
      if (assessmentState === "outcome") {
        sessionStorage.setItem("OutcomeFlow_showSignInScreen", "false")
      }

      navigate(`/assessment/${assessmentId}`, { replace: true })
    } catch (err) {
      console.error("Error during native sign-in:", err)
      setError({ ...error, submission: err.message })
      return
    }
  }

  // Retrieve the email from sessionStorage when the component mounts
  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email")
    if (storedEmail) {
      setEmail(storedEmail)
    }
  }, [])

  // useEffect to track form field validity
  useEffect(() => {
    setFormValid(email.length > 0 && password.length > 0 && !error.email)
  }, [email, password])

  return (
    <div className="signin">
      <h1 className="sr-only">Sign in with an existing account</h1>
      <form className="signin-form" onSubmit={handleSubmit}>
        <label className={`form-group ${ error.email || error.submission ? 'error' : null }`} htmlFor="email">
          <span>Email Address</span>

          <div>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="name@example.com"
              value={email}
              onChange={handleChange}
              aria-invalid={error.email}
            />

            {error.email === true && <span aria-live="assertive">Invalid Email address. Please enter a valid email in this format: example@gmail.com</span>}
          </div>
        </label>

        <label className={`form-group ${ error.submission ? 'error' : null }`} htmlFor="password">
          <span>Password</span>

          <div className="relative">
            <input
              type={passwordVisible ? "text" : "password"}
              id="password"
              name="password"
              placeholder="password"
              value={password}
              onChange={handleChange}
            />

            <button className="toggle-password" onClick={togglePasswordVisibility} type="button">
              <img src={passwordVisible ? icons["eye"] : icons["eye-slash"]} alt="Toggle visibility" />
            </button>
          </div>
        </label>

        <div className="text-center md:text-left">
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>

        <button type="submit" disabled={ !formValid }>Sign In</button>

        <div>
          <Link to={{ pathname: "/create-account", search: searchParams.toString() }} replace>
            Dont have an account? Create an account
          </Link>
        </div>

        {error.submission && <div className="form-error" aria-live="assertive">{error.submission}</div>}
      </form>

      <div className="divider">
        <p>Or</p>
      </div>

      <div className="signin-options">
        <AuthWrapper onSubmit={onSubmit} displayTitle={false} />
      </div>
    </div>
  )
}

export default SignIn