function NitratesMoreInfo() {
  return (
    <div className="nitrates-more-info">
      <p className="nitrates-info-text">Nitrates may be tablets, sprays, creams, patches, or injectable products.</p>
      <p className="nitrates-info-text">Never use Avanafil OTC with a nitrate medicine. Using nitrates with this product could cause a life-threatening drop in blood pressure and could lead to a heart attack or stroke.</p>
      <div data-testid="nitrates-info-list" className="nitrates-info-list">
        <p>This is <b>NOT</b> a complete list, but some commonly prescribed nitrates are:</p>
        <ul className="nitrates-info-list-items">
          <li>Imdur (Isosorbide Mononitrate)</li>
          <li>Isordil (Isosorbide Dinitrate)</li>
          <li>Nitrostat (Nitroglycerin sublingual)</li>
        </ul>
      </div>
      <p className="nitrates-info-text">If you are unsure whether you have been prescribed or taken these or any other nitrate, consult a doctor or pharmacist before taking Avanafil OTC.</p>
    </div>
  )
}

export default NitratesMoreInfo