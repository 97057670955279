import React from "react"
import { Link } from "react-router-dom"
import LayoutScreen from "../../components/Layout"
import errorCodeImg from "../../img/404.svg"
import "./ErrorPages.css"

function PageNotFound() {
  return (
    <LayoutScreen>
      <div className="error-page-content not-found">
        <img src={errorCodeImg} alt="page not found illustration" />

        <h1 role="alert">
          Sorry, page not found...
        </h1>

        <p>
          The address you entered may be wrong or the page may have moved.
          Please check the address or return to the homepage to find what
          you are looking for.
        </p>

        <Link to="/" className="btn secondary text-center" replace={true}>
          Back to Homepage
        </Link>
      </div>
    </LayoutScreen>
  )
}

export default PageNotFound
