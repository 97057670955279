import { useState } from "react"

import PrivacyRest from "../PrivacyRest"
import GenericModal from "../Modal"

import Button from "./Button"

import './PrivacyNotice.css'

function PrivacyNotice({ onSubmit }) {
  const [isNoticeOpen, setIsNoticeOpen] = useState(false)

  const togglePrivacy = () => {
    setIsNoticeOpen(!isNoticeOpen)
  }

  const handleSubmit = () => {
    onSubmit({ AcceptPrivacyNotice: new Date().toISOString() })
  }

  return (
    <section className="privacy-notice">
      <div className="flex justify-center">
        <button className="privacy-link" onClick={togglePrivacy}>
          Click here to read the full privacy notice
        </button>
      </div>
      {isNoticeOpen && (
        <GenericModal
          isOpen={isNoticeOpen}
          buttonText="Back"
          onRequestClose={togglePrivacy}
          stickyButton={true}
          title="Full Privacy Notice"
        >
          <PrivacyRest />
        </GenericModal>
      )}

      <div className="buttons">
        <Button onClick={handleSubmit} className="secondary">
          I Accept
        </Button>
      </div>
    </section>
  )
}

export default PrivacyNotice