import { useEffect } from "react"
import { authClient } from "../../client"

import BaseScreen from "./BaseScreen"

function KickoutScreen({ id, title, onClick, onEvent, children }) {

  useEffect(() => {
    onEvent({ componentId: id, type: "SCREEN_DISPLAYED" })
  }, [id])

  // remove auth if kicked out
  useEffect(() => {
    // TODO/FIXME
    // since this clears the token on kickout, if the user refreshes the page on a kickout screen they will get weird behavior / blank screen
    authClient.clearToken()
  }, [])

  return (
    <BaseScreen
      title={title}
      onClick={onClick}
      showButton={false}
      showBackButton={false}
      showButtonContainer={false}
    >
      {children}
    </BaseScreen>
  )
}

export default KickoutScreen